import { FC, useContext, useEffect, useRef, useState } from 'react'
import { PaymentWidgetInstance, loadPaymentWidget } from '@tosspayments/payment-widget-sdk'
import { nanoid } from 'nanoid'
import { Layout } from '../layout'
import { GlobalContext, UserContext } from '../contexts'
import RegExp from '../utils/RegExp'

type Props = {
    item: any
    cancelHandler: () => void
}
const OnlinePayment: FC<Props> = ({ item, cancelHandler }) => {
    const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null)
    const { user } = useContext(UserContext)

    const globalContext = useContext(GlobalContext)
    const { config } = globalContext.globalValue
    const categories: any[] = globalContext.globalValue.serviceCategory
    const amount = config.apply_travel_cost ? item.cost + item.travel_cost : item.cost

    const serviceName = [
        categories.find((category) => category.id == item.category_depth_1).category_name,
        categories.find((category) => category.id == item.category_depth_2).category_name,
        categories.find((category) => category.id == item.category_depth_3).category_name,
    ].join(' > ')
    const companyName = item.manager?.company_name

    // const serviceName = `${} > ${
    //     categories.find((category) => category.id == item.category_depth_2).category_name
    // } > ${categories.find((category) => category.id == item.category_depth_3).category_name}`
    const renderPayment = async (userId: string) => {
        const clientKey: string = process.env.REACT_APP_TOSS_CLIENT_KEY ?? ''
        const paymentWidget = await loadPaymentWidget(clientKey, userId)

        paymentWidget.renderPaymentMethods('#payment-widget', amount)
        paymentWidget.renderAgreement('#agreement-widget')

        paymentWidgetRef.current = paymentWidget
    }

    useEffect(() => {
        renderPayment(`service_${item.id}_${item.user_id}`)
    }, [])

    const clickPayHandler = async () => {
        const widget = paymentWidgetRef.current
        try {
            await widget?.requestPayment({
                orderId: nanoid(),
                orderName: companyName ?? '주식회사 인프라케이',
                customerName: user.companyName,
                customerEmail: user.email,
                successUrl: `${window.location.origin}/pay/result/success/${item.id}`,
                failUrl: `${window.location.origin}/pay/result/failure/${item.id}`,
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='container'>
            <div className='wrapper'>
                <label className='label'>서비스내용</label>
                <span>
                    <span>{serviceName}</span>
                </span>
            </div>
            <div className='wrapper'>
                <label className='label'>출장비</label>
                {config.apply_travel_cost ? (
                    <span>{RegExp.replaceCost(item.travel_cost.toString())}원</span>
                ) : (
                    <span>
                        <span className='strike'>{RegExp.replaceCost(item.travel_cost.toString())}원</span>
                        &nbsp;&nbsp;출장비 무료
                    </span>
                )}
            </div>
            <div className='wrapper'>
                <label className='label'>견적단가</label>
                <span>{RegExp.replaceCost(item.cost.toString())}원</span>
            </div>
            <div className='wrapper'>
                <label className='label'>결제금액</label>
                <span>
                    <span>{RegExp.replaceCost(amount.toString())}원</span>
                    &nbsp; &nbsp;
                    <span>{config.apply_travel_cost ? '(출장비+견적단가)' : '(출장비 무료)'}</span>
                </span>
            </div>
            <div id='payment-widget' className='wrapper'></div>
            <div id='agreement-widget' className='wrapper'></div>

            <div className='buttons center'>
                <button type='button' className='info' onClick={clickPayHandler}>
                    결제하기
                </button>
                <button type='button' className='error' onClick={cancelHandler}>
                    취소
                </button>
            </div>
        </div>
    )
}

export default OnlinePayment

import { ReactNode, useEffect, useState } from 'react'

type PaginationOption = {
    blockSize: number
    totalCount: number
    size: number
}
const usePagination = ({ blockSize, totalCount, size }: PaginationOption): [number, ReactNode, () => void] => {
    const [page, setPage] = useState<number>(1)
    const [pagination, setPagination] = useState<number[]>([])
    const [pageOption, setPageOption] = useState({
        block: 1,
        hasPrevBlock: false,
        hasNextBlock: false,
        hasNextPage: false,
        hasPrevPage: false,
    })
    const initPage = () => {
        setPage(1)
    }
    useEffect(() => {
        const totalPage = Math.ceil(totalCount / size)

        const totalBlock = Math.ceil(totalPage / blockSize)
        const block = Math.ceil(page / blockSize)
        const startPageNumber = 1 + size * (block - 1)
        // const endPageNumber = Math.min(totalPage - (block - 1) * blockSize, 10)
        const endPageNumber = totalPage - (block - 1) * blockSize

        const pageList: number[] = []
        for (let i = startPageNumber; i < endPageNumber + startPageNumber; i++) {
            pageList.push(i)
        }

        setPagination(pageList)
        setPageOption({
            block: block,
            hasNextBlock: totalBlock > block,
            hasPrevBlock: block > 1,
            hasNextPage: totalPage > page,
            hasPrevPage: page > 1,
        })
    }, [totalCount, page])

    const paginationContainer = (
        <div className='pagination'>
            {pageOption.hasPrevBlock && (
                <span
                    onClick={() => {
                        setPage((pageOption.block - 1) * blockSize)
                    }}
                >
                    &lt;&lt;
                </span>
            )}
            {pageOption.hasPrevPage && (
                <span
                    onClick={() => {
                        setPage(page - 1)
                    }}
                >
                    &lt;
                </span>
            )}
            {pagination.map((pageNumber) => {
                return pageNumber == page ? (
                    <span className='disabled' key={pageNumber}>
                        {pageNumber}
                    </span>
                ) : (
                    <span
                        onClick={() => {
                            setPage(pageNumber)
                        }}
                        key={pageNumber}
                    >
                        {pageNumber}
                    </span>
                )
            })}
            {pageOption.hasNextPage && (
                <span
                    onClick={() => {
                        setPage(page + 1)
                    }}
                >
                    &gt;
                </span>
            )}
            {pageOption.hasNextBlock && (
                <span
                    onClick={() => {
                        setPage(pageOption.block * blockSize + 1)
                    }}
                >
                    &gt;&gt;
                </span>
            )}
        </div>
    )

    return [page, paginationContainer, initPage]
}
export default usePagination

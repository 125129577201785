import React, { useContext, useEffect, useState } from 'react'
import { Layout } from '../../layout'
import { UserContext } from '../../contexts'
import { useNavigate, useParams } from 'react-router-dom'
import boardList from '../../json/board.json'

import DateFormat from '../../utils/DateFormat'
import useFetch from '../../hooks/useFetch'

const BoardDelete = () => {
    const userContext = useContext(UserContext)
    const { boardType, boardId } = useParams()
    const navigate = useNavigate()
    const boardTitle = boardList.find((board) => board.id.toString() == boardType)?.name
    const [item, setItem] = useState<any>({})
    const { doFetchJson } = useFetch()
    useEffect(() => {
        doFetchJson(`/board/${boardType}/${boardId}`, 'GET', null, (data) => {
            setItem(data.item)
        })
    }, [])
    const deleteHandler = () => {
        doFetchJson(
            `/board/${boardType}`,
            'DELETE',
            JSON.stringify({
                boardId: boardId,
                userId: userContext.user.id,
            }),
            () => {
                alert('삭제되었습니다.')
                navigate(`/board/${boardType}`)
            }
        )
    }

    return (
        <Layout title={`${boardTitle} 삭제`}>
            <div className='container'>
                <form>
                    <div className='wrapper'>
                        <label className='label'>제목</label>
                        <span>{item.title}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>작성일</label>
                        <span>{DateFormat.DateTime(new Date(item.create_at))}</span>
                    </div>
                    <p style={{ fontSize: 18, fontWeight: 700, color: 'red', textAlign: 'center' }}>
                        삭제하시면 복구 하실수 없습니다. 정말 삭제 하시겠습니까?
                    </p>
                    <div className='buttons right'>
                        <button type='button' className='error' onClick={deleteHandler}>
                            삭제
                        </button>
                        <button
                            type='button'
                            className='primary'
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            취소
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default BoardDelete

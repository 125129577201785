export default class RegExp {
    static checkEmail = (email: string): boolean => {
        return /^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}$/g.test(email)
    }
    static checkPassword = (password: string): boolean => {
        // return /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&#.~_-])[A-Za-zd@$!%*?&#.~_-]{8,20}$/.test(password)
        return /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/.test(password)
    }
    static replaceContact = (contact: string): string => {
        return contact
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{3})(\d{3,4})(\d{4}){0,11}$/g, '$1-$2-$3')
            .replace(/(\-{1,2})$/g, '')
    }
    static replaceCost = (cost: string, maxCost?: number): string => {
        let value = parseInt(cost.replace(/[^\d]/g, ''), 10)

        if (isNaN(value)) {
            value = 0
        }
        if (maxCost) {
            if (value > maxCost) {
                value = maxCost
            }
        }

        return value.toLocaleString()
    }
    static replaceBusinessNumber = (businessNumber: string): string => {
        return businessNumber
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{0,3})(\d{0,2})(\d{0,5}){0,12}$/g, '$1-$2-$3')
            .replace(/(\-{1,2})$/g, '')
    }
}

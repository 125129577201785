import React, { FC, useContext, useEffect } from 'react'
import { UserContext } from '../../contexts'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
type Props = {
    result: boolean
}
const PayResult: FC<Props> = ({ result }) => {
    const { serviceId } = useParams()
    const userContext = useContext(UserContext)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const { doFetchJson } = useFetch()
    useEffect(() => {
        doFetchJson(
            '/pay/on',
            'POST',
            JSON.stringify({
                result: result,
                serviceId: serviceId,
                userId: userContext.user.id,
                payType: searchParams.get('paymentType'),
                orderId: searchParams.get('orderId'),
                payId: searchParams.get('paymentKey'),
                amount: searchParams.get('amount') ?? 0,
            }),
            () => {
                alert('결제가 완료 되었습니다.')
                navigate(`/service`)
            }
        )
    }, [])
    return <div>결제정보 저장 중입니다 잠시만 기다려 주세요.</div>
}

export default PayResult

import { ReactNode, useContext, useEffect, useState } from 'react'
import { Layout } from '../../layout'
import tagList from '../../json/tag.json'
import typeList from '../../json/board.json'
import { useNavigate, useParams } from 'react-router-dom'
import usePagination from '../../hooks/usePagination'
import { Link } from 'react-router-dom'
import { UserContext } from '../../contexts'
import DateFormat from '../../utils/DateFormat'
import useFetch from '../../hooks/useFetch'

const BoardList = () => {
    const { boardType } = useParams()
    const [items, setItems] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const navigate = useNavigate()
    const userContext = useContext(UserContext)
    const { user } = userContext
    const { doFetchJson } = useFetch()
    const defaultFilterOptions = {
        title: '',
        size: 10,
        order: 'desc',
        sort: 'id',
        tag: 0,
    }
    const [filterOptions, setFilterOptions] = useState(defaultFilterOptions)

    const [page, paginationContainer] = usePagination({
        blockSize: 10,
        totalCount: totalCount,
        size: filterOptions.size,
    })

    const setListItems = () => {
        const queryString = Object.entries({ ...filterOptions, page: page, user: user.id, auth: user.auth })
            .map((item) => {
                return item.join('=')
            })
            .join('&')
        doFetchJson(`/board/${boardType}?${queryString}`, 'GET', null, (data) => {
            setItems(data.items)
            setTotalCount(data.totalCount)
        })
    }
    const onChangeFilterHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        setListItems()
    }, [filterOptions, page])
    useEffect(() => {
        setListItems()
        setFilterOptions(defaultFilterOptions)
    }, [boardType])

    let active = 'board'
    if (boardType == '1') {
        active = 'board1'
    } else if (boardType == '2') {
        active = 'board2'
    }
    return (
        <Layout active={active}>
            <div className='container list'>
                <div className='filter'>
                    <div className='wrapper'>
                        <label className='label'>구분</label>
                        <select name='tag' value={filterOptions.tag} onChange={onChangeFilterHandler}>
                            <option value={0}>전체</option>
                            {tagList.map((tag, index) => {
                                return (
                                    <option value={tag.id} key={index}>
                                        {tag.name}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>

                <div className='board list'>
                    <div className='header'>
                        <h5>{typeList.find((type) => type.id.toString() == boardType)?.name}</h5>
                    </div>
                    <div className='content'>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '10%' }}>번호</th>
                                    {/* <th style={{ width: '10%' }}>등록No.</th> */}
                                    <th style={{ width: '15%' }}>구분</th>
                                    <th style={{ width: '30%' }}>제목</th>
                                    <th style={{ width: '40%' }}>작성날짜</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!items.length ? (
                                    <tr>
                                        <td colSpan={5} style={{ height: '2rem' }}>
                                            조회된 데이터가 없습니다.
                                        </td>
                                    </tr>
                                ) : (
                                    items.map((item: any, index) => {
                                        const numbering = totalCount - (page - 1) * defaultFilterOptions.size - index
                                        return (
                                            <tr
                                                key={index}
                                                onClick={() => {
                                                    navigate(`/board/${item.type}/view/${item.id}`)
                                                }}
                                            >
                                                <td>{numbering}</td>
                                                {/* <td>{item.id}</td> */}
                                                <td>{typeList.find((type) => type.id == item.type)?.name}</td>
                                                <td>{item.title}</td>
                                                <td>{DateFormat.Date(new Date(item.create_at))}</td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                    {paginationContainer}
                </div>
                <div className='buttons right'>
                    {userContext.user.auth == 4 && (
                        <Link to={`/board/${boardType}/write`} className='primary'>
                            글쓰기
                        </Link>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default BoardList

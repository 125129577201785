import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Layout } from '../../layout'
import { GlobalContext, UserContext } from '../../contexts'

import statusList from '../../json/status.json'
import scoreList from '../../json/score.json'
import atList from '../../json/at.json'
import payList from '../../json/pay.json'
import useFileUploader from '../../hooks/useFileUploader'
import { useMessage } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import DateFormat from '../../utils/DateFormat'
import useFetch from '../../hooks/useFetch'
import RegExp from '../../utils/RegExp'
type Props = {
    item: any
    managerList: any[]
    setItemHandler: () => void
}
const ServiceAdmin: FC<Props> = ({ item, managerList, setItemHandler }) => {
    const plus3Date = new Date()
    plus3Date.setDate(plus3Date.getDate() + 3)
    const navigate = useNavigate()
    const globalContext = useContext(GlobalContext)
    const userContext = useContext(UserContext)
    const { user } = userContext
    const categories: any[] = globalContext.globalValue.serviceCategory
    const { doFetchJson, doFetchForm } = useFetch()
    const [formData, setFormData] = useState<any>({
        serviceDate: 0,
        serviceDateAt: 1,
        status: item.status,
        travelCost: item.travel_cost,
        cost: item.cost,
        managerId: item.manager_id,
    })
    const serviceManagerList = managerList.filter((manager) => {
        return manager.service_type == item.category_depth_1
    })
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const [setMessage, messageContainer] = useMessage()
    const [upFiles, upFilesContainer, upFilesResultContainer] = useFileUploader({
        maxFileCount: 5,

        defaultFiles: item.files.uploadFiles,
    })
    const [estimateFiles, estimateFileContainer, estimateFileResultContainer] = useFileUploader({
        maxFileCount: 5,

        defaultFiles: item.files.estimateFiles,
    })
    const [reportFiles, reportFileContainer, reportFileResultContainer] = useFileUploader({
        maxFileCount: 2,

        defaultFiles: item.files.reportFiles,
    })
    const [completeFiles, completeFileContainer, completeFileResultContainer] = useFileUploader({
        maxFileCount: 10,

        defaultFiles: item.files.completeFiles,
    })
    // console.log(item.files)
    const [documentFiles, documentFileContainer, documentFileResultContainer] = useFileUploader({
        maxFileCount: 0,

        defaultFiles: item.files.documentFiles,
    })

    const confirmManagerHandler = () => {
        setMessage('')
        if (!formData.managerId) {
            setMessage('매니저 업체를 선택 해주세요.')
            return false
        }
        doFetchJson(
            '/service/step/1',
            'PUT',
            JSON.stringify({
                serviceId: item.id,
                managerId: formData.managerId,
                userId: user.id,
            }),
            () => {
                alert('매니저가 선정되었습니다.')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )

        //업체 확정
    }

    const requestConfirmCostHandler = () => {
        //견적승인요청 to User
        setMessage('')
        doFetchJson(
            '/service/step/3',
            'PUT',
            JSON.stringify({
                serviceId: item.id,
            }),
            () => {
                alert('견적 승인 처리 되었습니다')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const requestPayHandler = () => {
        //결제요청 고객에게
        setMessage('')
        doFetchJson(
            '/service/step/6',
            'PUT',
            JSON.stringify({
                serviceId: item.id,
            }),
            () => {
                alert('결제요청 처리 되었습니다.')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const finishServiceHandler = () => {
        //서비스종료
        setMessage('')
        doFetchJson(
            '/service/step/9',
            'PUT',
            JSON.stringify({
                serviceId: item.id,
            }),
            () => {
                alert('서비스가 종료되었습니다.')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const checkOfflinePayHandler = () => {
        //오프라인결제 입금 확인
        setMessage('')
        doFetchJson(
            '/service/step/8',
            'PUT',
            JSON.stringify({
                serviceId: item.id,
            }),
            () => {
                alert('입금 확인 처리 되었습니다.')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const [serviceFormData, setServiceFormData] = useState<any>({
        categoryDepth1: item.category_depth_1,
        categoryDepth2: item.category_depth_2,
        categoryDepth3: item.category_depth_3,
        title: item.title,
        content: item.content,
        status: item.status,
        payType: item.pay_type,
        description: item.description,
    })
    const changeServiceFormHandler = (e: React.ChangeEvent<any>) => {
        setServiceFormData({ ...serviceFormData, [e.target.name]: e.target.value })
    }
    const saveServiceFormHandler = () => {
        setMessage('')
        if (!serviceFormData.title) {
            setMessage('접수 제목을 입력해주세요')
            return
        }
        if (!serviceFormData.content) {
            setMessage('요청사항을 입력해주세요')
            return
        }
        if (serviceFormData.status > 0 && !formData.managerId) {
            setMessage('가맹점을 선택 해 주세요.')
            return
        }
        const fetchBody = new FormData()
        fetchBody.append('serviceId', item.id)

        for (const key in serviceFormData) {
            fetchBody.append(key, serviceFormData[key])
        }
        upFiles.forEach((file) => {
            fetchBody.append('uploadFiles', file)
        })

        doFetchForm(
            '/service/service',
            'PUT',
            fetchBody,
            () => {
                alert('수정되었습니다.')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const [userForm, setUserForm] = useState({
        companyName: item.company_name,
        teamName: item.team_name,
        userName: item.user_name,
        userPhone: item.user_phone,
        userEmail: item.user_email,
    })
    const changeUserFormHandler = (e: React.ChangeEvent<any>) => {
        if (e.target.name == 'userPhone') {
            setUserForm({ ...userForm, [e.target.name]: RegExp.replaceContact(e.target.value) })
        } else {
            setUserForm({ ...userForm, [e.target.name]: e.target.value })
        }
    }
    const saveUserFormHandler = () => {
        setMessage('')
        if (!userForm.companyName) {
            setMessage('기업명을 입력 해 주세요.')
            return
        }
        if (!userForm.teamName) {
            setMessage('부서명을 입력 해 주세요.')
            return
        }
        if (!userForm.userName) {
            setMessage('담당자명을 입력 해 주세요.')
            return
        }
        if (!userForm.userPhone) {
            setMessage('담당자 연락처를 입력 해 주세요.')
            return
        }
        if (!userForm.userEmail) {
            setMessage('담당자 이메일을 입력 해 주세요.')
            return
        }
        doFetchJson(
            '/service/user',
            'PUT',
            JSON.stringify({ ...userForm, serviceId: item.id }),
            () => {
                alert('수정되었습니다.')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }

    const [costFormData, setCostFormData] = useState<any>({
        serviceDate: DateFormat.Date(item.service_date ? new Date(item.service_date) : new Date()),
        serviceDateAt: item.service_date_at || 0,
        travelCost: RegExp.replaceCost(item.travel_cost.toString()),
        cost: RegExp.replaceCost(item.cost.toString(), 10000000000),
        directDate: item.service_date
            ? DateFormat.Date(new Date(item.service_date))
            : DateFormat.Date(new Date(item.service_date_1)),
        // directDateAt: item.service_date_at ?? item.service_date_1_at ?? 1,
        directDateAt: item.service_date_at ? item.service_date_at : item.service_date_1_at ? item.service_date_1_at : 1,
    })
    const changeCostFormHandler = (e: React.ChangeEvent<any>) => {
        if (e.target.name == 'cost') {
            let maxCost = 10000000
            if (serviceFormData.payType == 1) {
                //현금
                maxCost = 10000000000
            }
            setCostFormData({ ...costFormData, cost: RegExp.replaceCost(e.target.value, maxCost) })
        } else if (e.target.name == 'travelCost') {
            setCostFormData({ ...costFormData, travelCost: RegExp.replaceCost(e.target.value) })
        } else {
            setCostFormData({ ...costFormData, [e.target.name]: e.target.value })
        }
    }
    useEffect(() => {
        if (serviceFormData.payType != 1) {
            if (parseInt(costFormData.cost.replaceAll(',', '')) > 10000000) {
                alert('카드결제는 최대 1000만원까지 가능합니다.')
            }
        }
    }, [serviceFormData.payType])
    const saveCostHandler = () => {
        const fetchBody = new FormData()
        fetchBody.append('serviceId', item.id)

        if (costFormData.serviceDate == 0) {
            if (!costFormData.directDate) {
                setMessage('날짜를 선택 해 주세요.')
                return
            }
            fetchBody.append('serviceDate', costFormData.directDate)
            fetchBody.append('serviceDateAt', costFormData.directDateAt)
        } else {
            fetchBody.append('serviceDate', costFormData.serviceDate)
            fetchBody.append('serviceDateAt', costFormData.serviceDateAt)
        }

        fetchBody.append('cost', costFormData.cost)
        fetchBody.append('travelCost', costFormData.travelCost)
        estimateFiles.forEach((file) => {
            fetchBody.append('estimateFiles', file)
        })
        doFetchForm(
            '/service/cost',
            'PUT',
            fetchBody,
            (data) => {
                // console.log(data)
                alert('수정되었습니다.')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const saveCompleteHandler = () => {
        const fetchBody = new FormData()
        fetchBody.append('serviceId', item.id)
        reportFiles.forEach((file) => {
            fetchBody.append('reportFiles', file)
        })
        completeFiles.forEach((file) => {
            fetchBody.append('completeFiles', file)
        })
        doFetchForm(
            '/service/complete',
            'PUT',
            fetchBody,
            (data) => {
                alert('수정되었습니다.')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const deleteServiceHandler = () => {
        setMessage('')
        const confirmDelete = window.confirm('삭제하시면 복구 하실수 없습니다. 삭제하시겠습니까?')
        if (confirmDelete) {
            doFetchJson(
                '/service',
                'DELETE',
                JSON.stringify({ serviceId: item.id }),
                () => {
                    alert('삭제되었습니다.')
                    navigate('/service')
                },
                (message) => {
                    setMessage(message)
                }
            )
        }
    }

    useMemo(() => {
        let serviceIndex: number = 0
        const serviceAt = [item.service_date_1_at, item.service_date_2_at, item.service_date_3_at]
        if (costFormData.serviceDate != 0) {
            ;[item.service_date_1, item.service_date_2, item.service_date_3].forEach((date, index) => {
                if (DateFormat.Date(new Date(date)) == costFormData.serviceDate) {
                    serviceIndex = index
                }
            })
        }
        setCostFormData({ ...costFormData, serviceDateAt: serviceAt[serviceIndex] })
        // setFormData({ ...formData, serviceDateAt: serviceAt[serviceIndex] })
    }, [costFormData.serviceDate])

    return (
        <Layout active='service2'>
            <div className='container'>
                <form>
                    <div className='wrapper'>
                        <p className='guide'>서비스 정보</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>대분류</label>
                        <select
                            name='categoryDepth1'
                            value={serviceFormData.categoryDepth1}
                            onChange={changeServiceFormHandler}
                        >
                            {categories
                                .filter((item) => {
                                    return item.depth == 1 && item.type == 1
                                })
                                .map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.category_name} {!item.enabled && '(준비중)'}
                                        </option>
                                    )
                                })}
                        </select>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>중분류</label>
                        <select
                            name='categoryDepth2'
                            value={serviceFormData.categoryDepth2}
                            onChange={changeServiceFormHandler}
                        >
                            {categories
                                .filter((item) => {
                                    return item.type == 2 && item.depth == 2 && item.parent_id == 0
                                })
                                .map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.category_name}
                                        </option>
                                    )
                                })}
                        </select>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>소분류</label>
                        <select
                            name='categoryDepth3'
                            value={serviceFormData.categoryDepth3}
                            onChange={changeServiceFormHandler}
                        >
                            {categories
                                .filter((item) => {
                                    return (
                                        item.type == 2 &&
                                        item.depth == 3 &&
                                        item.parent_id == serviceFormData.categoryDepth2
                                    )
                                })
                                .map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.category_name}
                                        </option>
                                    )
                                })}
                        </select>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>접수제목</label>
                        <input
                            type='text'
                            name='title'
                            value={serviceFormData.title}
                            onChange={changeServiceFormHandler}
                        />
                    </div>
                    <div className='wrapper'>
                        <label className='label ta'>요청사항</label>
                        <textarea name='content' value={serviceFormData.content} onChange={changeServiceFormHandler} />
                    </div>

                    <div className='wrapper'>
                        <label className='label'>결제수단</label>
                        <div className='switch input'>
                            {payList.map((pay, index) => {
                                return (
                                    <label key={index}>
                                        <input
                                            type='radio'
                                            name='payType'
                                            checked={serviceFormData.payType == pay.pay}
                                            value={pay.pay}
                                            onChange={changeServiceFormHandler}
                                        />
                                        {pay.name}
                                    </label>
                                )
                            })}
                        </div>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>첨부사진</label>
                        {upFilesContainer}
                    </div>
                    <div className='wrapper'>
                        <label className='label'>진행상황</label>
                        {item.status > 0 ? (
                            <select name='status' value={serviceFormData.status} onChange={changeServiceFormHandler}>
                                {statusList.map((status, index) => {
                                    return (
                                        <option key={index} value={status.status}>
                                            {status.name}
                                        </option>
                                    )
                                })}
                            </select>
                        ) : (
                            <span>{statusList.find((status) => status.status == item.status)?.name}</span>
                        )}
                    </div>
                    <div className='wrapper'>
                        <label className='label ta'>메모</label>
                        <textarea
                            name='description'
                            value={serviceFormData.description}
                            onChange={changeServiceFormHandler}
                        />
                    </div>
                    {messageContainer}
                    <div className='buttons center'>
                        <button type='button' className='primary' onClick={saveServiceFormHandler}>
                            서비스 수정
                        </button>
                    </div>
                    <div className='wrapper'>
                        <p className='guide'>고객 정보</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>기업명</label>
                        <input
                            type='text'
                            name='companyName'
                            value={userForm.companyName}
                            onChange={changeUserFormHandler}
                        />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>부서명</label>
                        <input type='text' name='teamName' value={userForm.teamName} onChange={changeUserFormHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>담당자</label>
                        <input type='text' name='userName' value={userForm.userName} onChange={changeUserFormHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>연락처</label>
                        <input
                            type='text'
                            name='userPhone'
                            value={userForm.userPhone}
                            onChange={changeUserFormHandler}
                        />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>이메일</label>
                        <input
                            type='text'
                            name='userEmail'
                            value={userForm.userEmail}
                            onChange={changeUserFormHandler}
                        />
                    </div>
                    <div className='buttons center'>
                        <button type='button' className='primary' onClick={saveUserFormHandler}>
                            고객 수정
                        </button>
                    </div>
                    <div className='wrapper'>
                        <p className='guide'>가맹점 정보</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>대리/가맹점</label>
                        {item.status == 0 || !item.manager ? (
                            <select name='managerId' onChange={onChangeHandler} value={formData.managerId}>
                                <option value={0} key={0}>
                                    선택
                                </option>
                                {serviceManagerList.map((manager, index) => {
                                    return (
                                        <option value={manager.id} key={index}>
                                            {manager.company_name}
                                        </option>
                                    )
                                })}
                            </select>
                        ) : (
                            <span>{item.manager.company_name}</span>
                        )}
                    </div>

                    <div className='wrapper'>
                        <p className='guide'>견적 정보</p>
                    </div>

                    <div className='wrapper'>
                        <label className='label'>작업일자</label>
                        <select name='serviceDate' onChange={changeCostFormHandler} value={costFormData.serviceDate}>
                            <option value={0}>직접입력</option>
                            <option value={DateFormat.Date(new Date(item.service_date_1))}>
                                {`${DateFormat.Date(new Date(item.service_date_1))} ${
                                    atList.find((at) => at.at == item.service_date_1_at)?.name
                                }`}
                            </option>
                            <option value={DateFormat.Date(new Date(item.service_date_2))}>
                                {`${DateFormat.Date(new Date(item.service_date_2))} ${
                                    atList.find((at) => at.at == item.service_date_2_at)?.name
                                }`}
                            </option>
                            <option value={DateFormat.Date(new Date(item.service_date_3))}>
                                {`${DateFormat.Date(new Date(item.service_date_3))} ${
                                    atList.find((at) => at.at == item.service_date_3_at)?.name
                                }`}
                            </option>
                        </select>
                        {costFormData.serviceDate != 0 && (
                            <div className='switch'>
                                {atList.map((at, index) => {
                                    return (
                                        <label key={index}>
                                            <input
                                                type='radio'
                                                name='serviceDateAt'
                                                checked={costFormData.serviceDateAt == at.at}
                                                value={at.at}
                                                onChange={changeCostFormHandler}
                                            />
                                            {at.name}
                                        </label>
                                    )
                                })}
                            </div>
                        )}
                        {/* <input
                            name='serviceDate'
                            type='date'
                            onChange={changeCostFormHandler}
                            value={costFormData.serviceDate}
                            min={DateFormat.Date(plus3Date)}
                        />
                        <div className='switch'>
                            {atList.map((at, index) => {
                                return (
                                    <label key={index}>
                                        <input
                                            type='radio'
                                            name='serviceDateAt'
                                            defaultChecked={costFormData.serviceDateAt == at.at}
                                            value={at.at}
                                            onChange={changeCostFormHandler}
                                        />
                                        {at.name}
                                    </label>
                                )
                            })}
                        </div> */}
                    </div>
                    {costFormData.serviceDate == 0 && (
                        <div className='wrapper'>
                            <label className='label'>직접입력</label>
                            <input
                                name='directDate'
                                type='date'
                                onChange={changeCostFormHandler}
                                value={costFormData.directDate}
                                min={DateFormat.Date(plus3Date)}
                            />
                            {costFormData.serviceDate == 0 && (
                                <div className='switch'>
                                    {atList.map((at, index) => {
                                        return (
                                            <label key={index}>
                                                <input
                                                    type='radio'
                                                    name='directDateAt'
                                                    defaultChecked={costFormData.directDateAt == at.at}
                                                    value={at.at}
                                                    onChange={changeCostFormHandler}
                                                />
                                                {at.name}
                                            </label>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                    <div className='wrapper'>
                        <label className='label'>견적서</label>
                        {estimateFileContainer}
                    </div>
                    <div className='wrapper'>
                        <label className='label'>출장비</label>
                        <input
                            type='text'
                            name='travelCost'
                            value={costFormData.travelCost}
                            onChange={changeCostFormHandler}
                        />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>견적금액</label>
                        <input type='text' name='cost' value={costFormData.cost} onChange={changeCostFormHandler} />
                    </div>
                    <div className='buttons center'>
                        <button type='button' className='primary' onClick={saveCostHandler}>
                            견적 수정
                        </button>
                    </div>

                    {item.status >= 5 && item.complete_at && (
                        <>
                            <div className='wrapper'>
                                <p className='guide'>완료 정보</p>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>완료일자</label>
                                <span>{DateFormat.DateTime(new Date(item.complete_at))}</span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>완료보고서</label>
                                {reportFileContainer}
                            </div>
                            <div className='wrapper'>
                                <label className='label'>첨부사진(결과)</label>
                                {completeFileContainer}
                            </div>
                            <div className='buttons center'>
                                <button type='button' className='primary' onClick={saveCompleteHandler}>
                                    완료 수정
                                </button>
                            </div>
                        </>
                    )}
                    {item.pay && (
                        <>
                            <div className='wrapper'>
                                <p className='guide'>결제 정보</p>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>요청일시</label>
                                <span>{DateFormat.DateTime(new Date(item.pay.create_at))}</span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>결제방식</label>
                                <span>{item.pay.pay_type}</span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>결제금액</label>
                                <span>{item.pay.amount}원</span>
                            </div>
                            {item.pay.pay_type == 'OFFLINE' && (
                                <>
                                    <div className='wrapper'>
                                        <p className='guide'>대금 청구시 필요사항</p>
                                    </div>
                                    <div className='wrapper'>
                                        <label className='label'>내용</label>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: item.description?.replace(/\n/g, '<br/>'),
                                            }}
                                        ></span>
                                    </div>
                                    <div className='wrapper'>
                                        <label className='label'>양식</label>
                                        {documentFileResultContainer}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {item.review?.user && (
                        <>
                            <div className='wrapper'>
                                <p className='guide'>고객 만족도</p>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>서비스만족도</label>
                                <span>
                                    {scoreList.find((score) => score.score == item.review.user.service_score)?.name}
                                </span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>작업만족도</label>
                                <span>
                                    {scoreList.find((score) => score.score == item.review.user.performance_score)?.name}
                                </span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>가격만족도</label>
                                <span>
                                    {scoreList.find((score) => score.score == item.review.user.cost_score)?.name}
                                </span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>건의사항</label>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: item.review.user.content?.replace(/\n/g, '<br/>'),
                                    }}
                                ></span>
                            </div>
                        </>
                    )}
                    {item.review?.manager && (
                        <>
                            <div className='wrapper'>
                                <p className='guide'>매니저 만족도</p>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>서비스만족도</label>
                                <span>
                                    {scoreList.find((score) => score.score == item.review.manager.service_score)?.name}
                                </span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>작업만족도</label>
                                <span>
                                    {
                                        scoreList.find((score) => score.score == item.review.manager.performance_score)
                                            ?.name
                                    }
                                </span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>가격만족도</label>
                                <span>
                                    {scoreList.find((score) => score.score == item.review.manager.cost_score)?.name}
                                </span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>건의사항</label>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: item.review.manager.content?.replace(/\n/g, '<br/>'),
                                    }}
                                ></span>
                            </div>
                        </>
                    )}

                    {messageContainer}

                    <div className='buttons center'>
                        {item.status == 0 ? (
                            <button type='button' className='info' onClick={confirmManagerHandler}>
                                매니저 확정
                            </button>
                        ) : (
                            <button type='button' className='disabled'>
                                매니저 확정
                            </button>
                        )}
                        {item.status == 2 ? (
                            <button type='button' className='info' onClick={requestConfirmCostHandler}>
                                견적 승인요청
                            </button>
                        ) : (
                            <button type='button' className='disabled'>
                                견적 승인요청
                            </button>
                        )}
                        {item.status == 5 ? (
                            <button type='button' className='info' onClick={requestPayHandler}>
                                결제요청
                            </button>
                        ) : (
                            <button type='button' className='disabled'>
                                결제요청
                            </button>
                        )}
                        {/* {item.status == 7 ? (
                            <button type='button' className='info' onClick={checkOfflinePayHandler}>
                                결제확인
                            </button>
                        ) : (
                            <button type='button' className='disabled'>
                                결제확인
                            </button>
                        )} */}
                        {item.status != 9 ? (
                            <button type='button' className='info' onClick={finishServiceHandler}>
                                서비스 종료
                            </button>
                        ) : (
                            <button type='button' className='disabled'>
                                서비스 종료
                            </button>
                        )}
                        <button type='button' className='error' onClick={deleteServiceHandler}>
                            삭제
                        </button>
                        <button
                            type='button'
                            className='primary'
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            목록
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default ServiceAdmin

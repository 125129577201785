import { GlobalProvider, ModalProvider, UserProvider } from './providers'
import AppRouter from './AppRouter'
import { CookiesProvider } from 'react-cookie'

const App = () => {
    return (
        <GlobalProvider>
            <UserProvider>
                <ModalProvider>
                    <CookiesProvider>
                        <AppRouter />
                    </CookiesProvider>
                </ModalProvider>
            </UserProvider>
        </GlobalProvider>
    )
}

export default App

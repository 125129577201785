import React from 'react'
import loadingImage from '../icons/icon_loading.svg'
const Loading = () => {
    return (
        <div className='loading-panel'>
            <img src={loadingImage} />
        </div>
    )
}

export default Loading

import { useContext, useEffect, useState } from 'react'
import { Layout } from '../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import boardList from '../../json/board.json'
import { Link } from 'react-router-dom'
import { UserContext } from '../../contexts'
import useFileUploader from '../../hooks/useFileUploader'
import useFetch from '../../hooks/useFetch'
import tagList from '../../json/tag.json'
const BoardView = () => {
    const userContext = useContext(UserContext)
    const { boardType, boardId } = useParams()
    const navigate = useNavigate()
    const boardTitle = boardList.find((board) => board.id.toString() == boardType)?.name
    const [item, setItem] = useState<any>({})
    const { doFetchJson } = useFetch()

    useEffect(() => {
        doFetchJson(
            `/board/${boardType}/${boardId}`,
            'GET',
            null,
            (data) => {
                setItem(data.item)
            },
            () => {
                navigate(-1)
            }
        )
    }, [])

    const [files, filesContainer, filesResultContainer] = useFileUploader({
        maxFileCount: 0,

        defaultFiles: item.files,
    })
    let active = 'board'
    if (boardType == '1') {
        active = 'board1'
    } else if (boardType == '2') {
        active = 'board2'
    }
    return (
        <Layout active={active}>
            <div className='container content'>
                <div className='wrapper'>
                    <label className='label'>번호</label>
                    <span>{item.id}</span>
                </div>
                <div className='wrapper'>
                    <label className='label'>구분</label>
                    <span>{tagList.find((tag) => tag.id.toString() == item.tag)?.name}</span>
                </div>
                <div className='wrapper'>
                    <label className='label'>공개여부</label>
                    <span>{item.user ? `${item.user.name} / ${item.user.email}` : '전체공개'}</span>
                </div>
                <div className='wrapper'>
                    <label className='label'>제목</label>
                    <span>{item.title}</span>
                </div>
                <div className='wrapper'>
                    <label className='label'>내용</label>
                    <span dangerouslySetInnerHTML={{ __html: item.content?.replace(/\n/g, '<br/>') }}></span>
                </div>
                {files.length != 0 && (
                    <div className='wrapper'>
                        <label className='label'>자료</label>
                        {filesResultContainer}
                    </div>
                )}

                <div className='buttons center'>
                    {userContext.user.auth == 4 ? (
                        <>
                            <Link to={`/board/${boardType}/update/${item.id}`} className='primary'>
                                수정
                            </Link>
                            <Link to={`/board/${boardType}/delete/${item.id}`} className='error'>
                                삭제
                            </Link>
                        </>
                    ) : null}

                    <button
                        type='button'
                        className='primary'
                        onClick={() => navigate(userContext.user.auth == 4 ? `/board/${boardType}` : `/board/1,2`)}
                    >
                        목록
                    </button>
                </div>
            </div>
        </Layout>
    )
}

export default BoardView

import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Layout } from '../../layout'
import { useNavigate } from 'react-router-dom'
import { GlobalContext, ModalContext, UserContext } from '../../contexts'
import { useMessage } from '../../hooks'
import statusList from '../../json/status.json'
import atList from '../../json/at.json'
import payList from '../../json/pay.json'
import useFileUploader from '../../hooks/useFileUploader'
import DateFormat from '../../utils/DateFormat'

import useFetch from '../../hooks/useFetch'
import RegExp from '../../utils/RegExp'
type Props = {
    item: any
    setItemHandler: () => void
    reviewHandler: () => void
}
const ServiceManager: FC<Props> = ({ item, setItemHandler, reviewHandler }) => {
    const navigate = useNavigate()
    const globalContext = useContext(GlobalContext)
    const userContext = useContext(UserContext)
    const { user } = userContext
    const categories: any[] = globalContext.globalValue.serviceCategory
    const [setMessage, messageContainer] = useMessage()
    const { doFetchJson, doFetchForm } = useFetch()
    const [formData, setFormData] = useState<any>({
        serviceDate: [item.service_date_1, item.service_date_2, item.service_date_3].includes(item.service_date)
            ? DateFormat.Date(new Date(item.service_date))
            : 0,
        serviceDateAt: item.service_date_at ?? 1,
        status: item.status,
        directDate: item.service_date
            ? DateFormat.Date(new Date(item.service_date))
            : DateFormat.Date(new Date(item.service_date_1)),
        // directDateAt: item.service_date_at ?? item.service_date_1_at ?? 1,
        directDateAt: item.service_date_at ? item.service_date_at : item.service_date_1_at ? item.service_date_1_at : 1,
        cost: RegExp.replaceCost(item.cost.toString()),
        travelCost: RegExp.replaceCost(item.travel_cost.toString()),
    })
    const plus3Date = new Date()
    plus3Date.setDate(plus3Date.getDate() + 3)
    const [upFiles, upFilesContainer, upFilesResultContainer] = useFileUploader({
        maxFileCount: 5,

        defaultFiles: item.files.uploadFiles,
    })
    const [estimateFiles, estimateFileContainer, estimateFileResultContainer] = useFileUploader({
        maxFileCount: 5,

        defaultFiles: item.files.estimateFiles,
    })
    const [reportFiles, reportFileContainer, reportFileResultContainer] = useFileUploader({
        maxFileCount: 2,

        defaultFiles: item.files.reportFiles,
    })
    const [completeFiles, completeFileContainer, completeFileResultContainer] = useFileUploader({
        maxFileCount: 10,

        defaultFiles: item.files.completeFiles,
    })
    const confirmCostHandler = () => {
        setMessage('')

        if (formData.serviceDate == 0 && !formData.directDate) {
            setMessage('작업일자를 선택 해 주세요.')
            return false
        }
        if (!formData.cost) {
            setMessage('견적단가를 입력 해주세요.')
            return false
        }
        if (!estimateFiles.length) {
            setMessage('견적서 파일을 등록 해주세요.')
            return false
        }

        const serviceDate = formData.serviceDate == '0' ? formData.directDate : formData.serviceDate
        const serviceDateAt = formData.serviceDate == '0' ? formData.directDateAt : formData.serviceDateAt
        const fetchBody = new FormData()

        fetchBody.append('userId', user.id.toString())
        fetchBody.append('serviceId', item.id)
        fetchBody.append('serviceDate', serviceDate)
        fetchBody.append('serviceDateAt', serviceDateAt.toString())
        fetchBody.append('travelCost', formData.travelCost)
        fetchBody.append('cost', formData.cost)
        estimateFiles.forEach((file) => {
            fetchBody.append('estimateFiles', file, file.name)
        })
        doFetchForm(
            '/service/step/2',
            'PUT',
            fetchBody,
            () => {
                alert('견적 승인 요청되었습니다.')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const reportHandler = () => {
        //상태가1 , 4 일때 작업 완료를 할 수있다
        setMessage('')
        if (item.status == 1) {
            //직접입력 && 직접입력 날짜 값이 없음
            if (formData.serviceDate == 0 && !formData.directDate) {
                setMessage('작업일자를 선택 해 주세요.')
                return false
            }
            if (!formData.cost) {
                setMessage('견적단가를 입력 해주세요.')
                return false
            }
            if (!estimateFiles.length) {
                setMessage('견적서 파일을 등록 해주세요.')
                return false
            }
        }

        if (!reportFiles.length) {
            setMessage('완료보고서 파일을 등록 해주세요.')
            return false
        }
        if (!completeFiles.length) {
            setMessage('첨부사진(결과) 파일을 등록 해주세요.')
            return false
        }
        const fetchBody = new FormData()

        if (item.status == 1) {
            const serviceDate =
                formData.serviceDate == '4'
                    ? formData.directDate
                    : DateFormat.Date(new Date(item[`service_date_${formData.serviceDate}`]))
            const serviceDateAt = formData.serviceDate == '4' ? formData.directDateAt : formData.serviceDateAt
            fetchBody.append('serviceDate', serviceDate)
            fetchBody.append('serviceDateAt', serviceDateAt.toString())
            fetchBody.append('travelCost', formData.travelCost)
            fetchBody.append('cost', formData.cost)
            estimateFiles.forEach((file) => {
                fetchBody.append('estimateFiles', file, file.name)
            })
        }

        fetchBody.append('serviceId', item.id)
        fetchBody.append('userId', user.id.toString())
        reportFiles.forEach((file) => {
            fetchBody.append('reportFiles', file, file.name)
        })
        completeFiles.forEach((file) => {
            fetchBody.append('completeFiles', file, file.name)
        })
        doFetchForm('/service/step/5', 'PUT', fetchBody, () => {
            alert('작업 완료 신청 되었습니다')
            setItemHandler()
        })
    }
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if (e.target.name == 'cost' || e.target.name == 'travelCost') {
            setFormData({ ...formData, [e.target.name]: RegExp.replaceCost(e.target.value) })
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const saveCostHandler = () => {
        const fetchBody = new FormData()
        fetchBody.append('serviceId', item.id)

        if (formData.serviceDate == 0) {
            if (!formData.directDate) {
                setMessage('날짜를 선택 해 주세요.')
                return
            }
            fetchBody.append('serviceDate', formData.directDate)
            fetchBody.append('serviceDateAt', formData.directDateAt)
        } else {
            fetchBody.append('serviceDate', formData.serviceDate)
            fetchBody.append('serviceDateAt', formData.serviceDateAt)
        }

        fetchBody.append('cost', formData.cost)
        fetchBody.append('travelCost', formData.travelCost)
        estimateFiles.forEach((file) => {
            fetchBody.append('estimateFiles', file)
        })

        doFetchForm(
            '/service/cost',
            'PUT',
            fetchBody,
            () => {
                alert('수정되었습니다.')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    useMemo(() => {
        let serviceIndex: number = 0
        const serviceAt = [item.service_date_1_at, item.service_date_2_at, item.service_date_3_at]
        if (formData.serviceDate != 0) {
            ;[item.service_date_1, item.service_date_2, item.service_date_3].forEach((date, index) => {
                if (DateFormat.Date(new Date(date)) == formData.serviceDate) {
                    serviceIndex = index
                }
            })
        }

        setFormData({ ...formData, serviceDateAt: serviceAt[serviceIndex] })
    }, [formData.serviceDate])

    return (
        <Layout active='service2'>
            <div className='container'>
                <form>
                    <div className='wrapper'>
                        <p className='guide'>서비스 정보</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>대분류</label>
                        <span>{categories.find((category) => category.id == item.category_depth_1).category_name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>중분류</label>
                        <span>{categories.find((category) => category.id == item.category_depth_2).category_name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>소분류</label>
                        <span>{categories.find((category) => category.id == item.category_depth_3).category_name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>접수제목</label>
                        <span>{item.title}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>요청사항</label>
                        <span dangerouslySetInnerHTML={{ __html: item.content }}></span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>진행상황</label>
                        <span>{statusList.find((status) => status.status.toString() == item.status)?.name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>결제수단</label>
                        <span>{payList.find((pay) => item.pay_type == pay.pay)?.name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>첨부사진</label>
                        {upFilesResultContainer}
                    </div>
                    <div className='wrapper'>
                        <p className='guide'>고객 정보</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>기업명</label>
                        <span>{item.company_name ?? ''}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>부서명</label>
                        <span>{item.team_name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>담당자</label>
                        <span>{item.user_name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>연락처</label>
                        <span>{item.user_phone}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>이메일</label>
                        <span>{item.user_email}</span>
                    </div>
                    <div className='wrapper'>
                        <p className='guide'>견적 정보</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>작업일자</label>
                        {item.status < 5 ? (
                            <>
                                <select name='serviceDate' onChange={onChangeHandler} value={formData.serviceDate}>
                                    <option value={0}>직접입력</option>
                                    <option value={DateFormat.Date(new Date(item.service_date_1))}>
                                        {`${DateFormat.Date(new Date(item.service_date_1))} ${
                                            atList.find((at) => at.at == item.service_date_1_at)?.name
                                        }`}
                                    </option>
                                    <option value={DateFormat.Date(new Date(item.service_date_2))}>
                                        {`${DateFormat.Date(new Date(item.service_date_2))} ${
                                            atList.find((at) => at.at == item.service_date_2_at)?.name
                                        }`}
                                    </option>
                                    <option value={DateFormat.Date(new Date(item.service_date_3))}>
                                        {`${DateFormat.Date(new Date(item.service_date_3))} ${
                                            atList.find((at) => at.at == item.service_date_3_at)?.name
                                        }`}
                                    </option>
                                </select>
                                {formData.serviceDate != 0 && (
                                    <div className='switch'>
                                        {atList.map((at, index) => {
                                            return (
                                                <label key={index}>
                                                    <input
                                                        type='radio'
                                                        name='serviceDateAt'
                                                        checked={formData.serviceDateAt == at.at}
                                                        value={at.at}
                                                        onChange={onChangeHandler}
                                                    />
                                                    {at.name}
                                                </label>
                                            )
                                        })}
                                    </div>
                                )}
                            </>
                        ) : (
                            <span>
                                {DateFormat.Date(new Date(item.service_date))}{' '}
                                {atList.find((at) => at.at == item.service_date_at)?.name}
                            </span>
                        )}
                    </div>

                    {formData.serviceDate == 0 && item.status < 5 && (
                        <div className='wrapper'>
                            <label className='label'>직접입력</label>
                            <input
                                name='directDate'
                                type='date'
                                onChange={onChangeHandler}
                                value={formData.directDate}
                                min={DateFormat.Date(plus3Date)}
                            />
                            {formData.serviceDate == 0 && (
                                <div className='switch'>
                                    {atList.map((at, index) => {
                                        return (
                                            <label key={index}>
                                                <input
                                                    type='radio'
                                                    name='directDateAt'
                                                    defaultChecked={formData.directDateAt == at.at}
                                                    value={at.at}
                                                    onChange={onChangeHandler}
                                                />
                                                {at.name}
                                            </label>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                    <div className='wrapper'>
                        <label className='label'>출장비</label>
                        {item.status < 5 ? (
                            <>
                                <input
                                    type='text'
                                    value={formData.travelCost}
                                    name='travelCost'
                                    onChange={onChangeHandler}
                                    className='cost'
                                />
                            </>
                        ) : (
                            <span>
                                {globalContext.globalValue.config['apply_travel_cost'] ? (
                                    <span>{RegExp.replaceCost(formData.travelCost)}원</span>
                                ) : (
                                    <span>
                                        <span className='strike'>{RegExp.replaceCost(formData.travelCost)}원</span>
                                        (출장비무료)
                                    </span>
                                )}
                            </span>
                        )}
                    </div>
                    <div className='wrapper'>
                        <label className='label'>견적단가</label>
                        {item.status < 5 ? (
                            <>
                                <input
                                    type='text'
                                    value={formData.cost}
                                    name='cost'
                                    onChange={onChangeHandler}
                                    className='cost'
                                />
                            </>
                        ) : (
                            <span>{RegExp.replaceCost(formData.cost, 0)}원</span>
                        )}
                    </div>
                    <div className='wrapper'>
                        <label className='label'>견적서</label>
                        {item.status < 5 ? estimateFileContainer : estimateFileResultContainer}
                    </div>
                    {item.status < 5 && (
                        <div className='buttons center'>
                            <button type='button' className='primary' onClick={saveCostHandler}>
                                견적 수정
                            </button>
                        </div>
                    )}

                    <div className='wrapper'>
                        <p className='guide'>완료 정보</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>완료일자</label>
                        <span>
                            {item.complete_at ? DateFormat.DateTime(new Date(item.complete_at)) : '서비스 진행중'}
                        </span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>완료보고서</label>
                        {item.status == 1 || item.status == 4 ? reportFileContainer : reportFileResultContainer}
                    </div>
                    <div className='wrapper'>
                        <label className='label'>첨부사진(결과)</label>
                        {item.status == 1 || item.status == 4 ? completeFileContainer : completeFileResultContainer}
                    </div>

                    {messageContainer}

                    <div className='buttons center'>
                        {item.status == 1 ? (
                            <button type='button' className='info' onClick={confirmCostHandler}>
                                견적 검토 요청
                            </button>
                        ) : (
                            <button type='button' className='disabled'>
                                견적 검토 요청
                            </button>
                        )}
                        {item.status == 1 || item.status == 4 ? (
                            <button type='button' className='info' onClick={reportHandler}>
                                작업완료
                            </button>
                        ) : (
                            <button type='button' className='disabled'>
                                작업완료
                            </button>
                        )}
                        {item.finish ? (
                            <button className='info' onClick={reviewHandler} type='button'>
                                만족도조사
                            </button>
                        ) : (
                            <button className='disabled' type='button'>
                                만족도조사
                            </button>
                        )}
                        <button
                            type='button'
                            className='primary'
                            onClick={() => {
                                navigate('/service')
                            }}
                        >
                            목록
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default ServiceManager

import { useEffect, useState } from 'react'
import useFetch from '../../hooks/useFetch'
import loading from '../../icons/icon_loading.svg'
import { useNavigate } from 'react-router-dom'
const AccessUser = () => {
    const { doFetchJson } = useFetch()
    const navigate = useNavigate()
    const auth = new URLSearchParams(window.location.search).get('auth')
    const userId = new URLSearchParams(window.location.search).get('userId')
    useEffect(() => {
        doFetchJson(
            '/user/access',
            'PUT',
            JSON.stringify({
                auth: auth,
                userId: userId,
            }),
            (data) => {
                alert(data.message)
                navigate('/')
            },
            (message) => {
                alert(message)
                navigate('/')
            }
        )
    }, [])
    return (
        <div className='loading-panel'>
            <img src={loading} />
        </div>
    )
}

export default AccessUser

import React, { FC, useContext, useEffect, useState } from 'react'
import { ModalContext, UserContext } from '../contexts'
import scoreList from '../json/score.json'
import useFetch from '../hooks/useFetch'
type Props = {
    serviceItem: any
    finishEvent: () => void
}
const ReviewWrite: FC<Props> = ({ serviceItem, finishEvent }) => {
    const modalContext = useContext(ModalContext)
    const userContext = useContext(UserContext)
    const { user } = userContext
    const { auth } = user

    const [formData, setFormData] = useState({
        serviceId: serviceItem.id,
        userId: user.id,
        userAuth: user.auth,
        serviceScore: 3,
        performanceScore: 3,
        costScore: 3,
        content: '',
    })
    const { doFetchJson } = useFetch()
    const writeReviewHandler = () => {
        doFetchJson(
            '/service/review',
            'POST',
            JSON.stringify(formData),
            (data) => {
                alert('등록되었습니다.')
                modalContext.activeModalHandler(null)
                finishEvent()
            },
            (message) => {
                console.log(message)
            }
        )
    }
    const changeHandler = (e: React.ChangeEvent<any>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    return (
        <div className='container'>
            {auth == 3 ? (
                <div className='wrapper'>
                    <label className='label'>업체명(기관명)</label>
                    <span>{serviceItem.company_name}</span>
                </div>
            ) : (
                <div className='wrapper'>
                    <label className='label'>가맹점명</label>
                    <span>{serviceItem.manager ? serviceItem.manager.company_name : '없음'}</span>
                </div>
            )}
            <div className='wrapper'>
                <label className='label'>서비스만족도</label>
                <div className='items flex'>
                    {scoreList.map((score, index) => {
                        return (
                            <label key={index}>
                                <input
                                    type='radio'
                                    name='serviceScore'
                                    value={score.score}
                                    defaultChecked={formData.serviceScore == score.score}
                                    onChange={changeHandler}
                                />
                                {score.name}
                            </label>
                        )
                    })}
                </div>
            </div>

            <div className='wrapper'>
                <label className='label'>작업만족도</label>
                <div className='items flex'>
                    {scoreList.map((score, index) => {
                        return (
                            <label key={index}>
                                <input
                                    type='radio'
                                    name='performanceScore'
                                    value={score.score}
                                    defaultChecked={formData.performanceScore == score.score}
                                    onChange={changeHandler}
                                />
                                {score.name}
                            </label>
                        )
                    })}
                </div>
            </div>

            <div className='wrapper'>
                <label className='label'>가격만족도</label>
                <div className='items flex'>
                    {scoreList.map((score, index) => {
                        return (
                            <label key={index}>
                                <input
                                    type='radio'
                                    name='costScore'
                                    value={score.score}
                                    defaultChecked={formData.costScore == score.score}
                                    onChange={changeHandler}
                                />
                                {score.name}
                            </label>
                        )
                    })}
                </div>
            </div>
            <div className='wrapper'>
                <label className='label ta'>건의사항</label>
                <textarea name='content' value={formData.content} onChange={changeHandler} />
            </div>

            <div className='buttons center'>
                <button type='button' className='primary' onClick={writeReviewHandler}>
                    작성하기
                </button>
                <button
                    type='button'
                    className='error'
                    onClick={() => {
                        modalContext.activeModalHandler(null)
                    }}
                >
                    닫기
                </button>
            </div>
        </div>
    )
}

export default ReviewWrite

import { useNavigate, useParams } from 'react-router-dom'
import levelList from '../../json/level.json'
import authList from '../../json/auth.json'
import { Layout } from '../../layout'
import { useEffect, useState } from 'react'
import usePagination from '../../hooks/usePagination'
import useFetch from '../../hooks/useFetch'
const UserList = () => {
    const { level } = useParams()
    const [getLelve, setGetLevel] = useState(level)
    const [items, setItems] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const navigate = useNavigate()
    const { doFetchJson } = useFetch()
    const authFilterList = authList.filter((a) => a.level.toString() == level)

    const [filterOptions, setFilterOptions] = useState({
        size: 10,
        order: 'desc',
        sort: 'id',
        type: 0,
        level: level,
        name: '',
    })
    const onChangeFilterHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value })
    }
    const [page, paginationContainer, initPage] = usePagination({
        blockSize: 10,
        totalCount: totalCount,
        size: filterOptions.size,
    })

    useEffect(() => {
        setListItems()
    }, [filterOptions, page])
    useEffect(() => {
        initPage()
        setFilterOptions({ ...filterOptions, level: level })
    }, [level])

    const setListItems = () => {
        const queryString = Object.entries({ ...filterOptions, page: page })
            .map((item) => {
                return item.join('=')
            })
            .join('&')
        doFetchJson(`/user?${queryString}`, 'GET', null, (data) => {
            console.log(data)
            setItems(data.items)
            setTotalCount(data.totalCount)
        })
    }
    const title = levelList.find((level) => level.level.toString() == filterOptions.level)?.name
    let active = 'user'
    if (level == '1') {
        active = 'user'
    } else if (level == '2') {
        active = 'manager'
    } else {
        active = 'admin'
    }
    return (
        <Layout active={active}>
            <div className='container list'>
                <div className='filter'>
                    {authFilterList.length > 1 && (
                        <div className='wrapper'>
                            <label className='label'>구분</label>
                            <select name='type' value={filterOptions.type} onChange={onChangeFilterHandler}>
                                <option value={0}>전체</option>
                                {authFilterList.map((auth, index) => {
                                    return (
                                        <option value={auth.auth} key={index}>
                                            {auth.name}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    )}
                    <div className='wrapper'>
                        <label className='label'>이름</label>
                        <input type='text' name='name' value={filterOptions.name} onChange={onChangeFilterHandler} />
                    </div>
                </div>
                <div className='user list'>
                    <div className='header'>
                        <h5>{title}</h5>
                    </div>
                    <div className='content'>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '4rem' }}>번호</th>
                                    <th style={{ width: '10rem' }}>이메일</th>
                                    <th style={{ width: '10rem' }}>이름</th>
                                    <th style={{ width: '15rem' }}>핸드폰번호</th>
                                    <th style={{ width: '25rem' }}>주소</th>
                                    <th style={{ width: '10rem' }}>기관명</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!items.length ? (
                                    <tr>
                                        <td colSpan={6} style={{ height: 140 }}>
                                            조회 내역이 없습니다.
                                        </td>
                                    </tr>
                                ) : (
                                    items.map((item: any, index) => {
                                        const numbering = totalCount - (page - 1) * filterOptions.size - index

                                        return (
                                            <tr
                                                key={index}
                                                onClick={() => {
                                                    // console.log(filterOptions.level)
                                                    if (filterOptions.level == '1') {
                                                        navigate(`/user/${filterOptions.type}/update/${item.id}`)
                                                    } else if (filterOptions.level == '2') {
                                                        navigate(`/user/3/update/${item.id}`)
                                                    } else if (filterOptions.level == '3') {
                                                        navigate(`/user/4/update/${item.id}`)
                                                    }
                                                }}
                                            >
                                                <td>{numbering}</td>
                                                <td>{item.email}</td>
                                                <td>{item.name}</td>

                                                <td>{item.phone}</td>
                                                <td>
                                                    [{item.zipcode}] {item.address} {item.address_detail}
                                                </td>
                                                <td>{item.company_name}</td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                    {paginationContainer}
                </div>
            </div>
        </Layout>
    )
}
export default UserList

import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Layout } from '../../layout'
import DaumPostcode, { Address } from 'react-daum-postcode'

import ModalContext from '../../contexts/ModalContext'
import { SignUpFormType } from '../../types/auth'
import UserSignUpForm from './UserSignUpForm'
import ManagerSignUpForm from './ManagerSignUpForm'
import AdminSignUpForm from './AdminSignUpForm'
import { Link } from 'react-router-dom'
import authList from '../../json/auth.json'
import { GlobalContext } from '../../contexts'
import RegExp from '../../utils/RegExp'
import useFetch from '../../hooks/useFetch'
import { useMessage } from '../../hooks'

type Props = {
    setStep: React.Dispatch<number>
    formData: SignUpFormType
    setFormData: React.Dispatch<SignUpFormType>
    setIdValid: React.Dispatch<boolean>
    idValid: boolean
    setEmailValid: React.Dispatch<boolean>
    emailValid: boolean
}
const SignUpForm: FC<Props> = ({ setStep, formData, setFormData, setIdValid, idValid, setEmailValid, emailValid }) => {
    const modalContext = useContext(ModalContext)
    const globalContext = useContext(GlobalContext)
    const serviceTypes = globalContext.globalValue.serviceCategory.filter(
        (category) => category.type == 1 && category.depth == 1
    )
    const { doFetchJson } = useFetch()
    //이메일 인증키 있는지
    const [emailValidKey, setEmailValidKey] = useState('')
    // const [message, setMessage] = useState('')
    const [setMessage, messageContainer] = useMessage()
    const submitFormHandler = (e: React.FormEvent) => {
        e.preventDefault()

        // if (!formData.id) {
        //     setMessage('아이디를 입력 해주세요.')
        //     return false
        // }
        if (!formData.email) {
            setMessage('이메일을 입력 해주세요.')
            return false
        }
        if (!emailValid) {
            setMessage('이메일 인증을 완료 해 주세요.')
            return false
        }
        // if (!idValid) {
        //     setMessage('아이디를 중복 검사를 해주세요.')
        //     return false
        // }
        if (!formData.password) {
            setMessage('비밀번호를 입력 해주세요.')
            return false
        }
        if (!RegExp.checkPassword(formData.password)) {
            setMessage('비밀번호 형식을 확인 해 주세요.')
            return
        }
        if (!formData.passwordConfirm) {
            setMessage('비밀번호 확인을 입력 해주세요.')
            return false
        }
        if (!RegExp.checkPassword(formData.passwordConfirm)) {
            setMessage('비밀번호 확인 형식을 확인 해 주세요.')
            return
        }
        if (formData.password !== formData.passwordConfirm) {
            setMessage('비밀번호와 비밀번호확인이 다릅니다.')
            return false
        }
        if (!formData.name) {
            setMessage('이름을 입력 해주세요.')
            return false
        }

        if (!formData.address) {
            setMessage('주소를 입력 해주세요.')
            return false
        }
        if (!formData.addressDetail) {
            setMessage('상세주소를 입력 해주세요.')
        }
        if (formData.auth == 1 || formData.auth == 2) {
            if (!formData.companyName) {
                setMessage('기관명(업체명)을 입력 해주세요.')
                return false
            }
        } else if (formData.auth == 3) {
            if (!formData.type) {
                setMessage('가입 구분을 선택 해주세요.')
                return false
            }
            if (!formData.companyName) {
                setMessage('가맹점명을 입력 해주세요.')
                return false
            }
            if (!formData.ceoName) {
                setMessage('대표자 이름을 입력 해주세요.')
                return false
            }
            if (!formData.businessNumber) {
                setMessage('사업자등록번호를 입력 해주세요.')
                return false
            }
            if (!formData.licenseNumber) {
                setMessage('업종면허번호를 입력 해주세요.')
                return false
            }
        } else {
            // if (!formData.ceoEmail) {
            //     setMessage('대표이사 이메일을 입력 해주세요.')
            //     return false
            // }
        }

        setStep(1)
    }

    // useEffect(() => {
    //     setIdValid(false)
    // }, [formData.id])
    useEffect(() => {
        setEmailValid(false)
    }, [formData.email])
    useEffect(() => {
        // setIdValid(false)
        setEmailValid(false)
    }, [formData.auth])
    const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (e.target) {
            let value = e.target.value
            if (e.target.name == 'phone') {
                value = RegExp.replaceContact(value)
            }
            if (e.target.name == 'businessNumber') {
                value = RegExp.replaceBusinessNumber(value)
            }
            setFormData({ ...formData, [e.target.name]: value })
        }
    }

    const sendEmailValidHandler = (e: React.MouseEvent) => {
        if (!formData.email) {
            setMessage('이메일을 입력 해 주세요.')
            return false
        }
        if (!RegExp.checkEmail(formData.email)) {
            setMessage('이메일 형식을 확인 해 주세요.')
            return
        }
        doFetchJson(
            '/auth/emailValid',
            'POST',
            JSON.stringify({
                email: formData.email,
                auth: formData.auth,
            }),
            (data) => {
                setEmailValidKey(data.validKey)
                setFormData({ ...formData, emailValid: '' })
                setMessage(data.message)
            },
            (message) => {
                setMessage(message)
            }
        )
    }

    const emailValidCheckHandler = () => {
        if (formData.emailValid === emailValidKey) {
            setMessage('인증 되었습니다.')
            setEmailValid(true)
            setEmailValidKey('')
        } else {
            setMessage('인증번호를 확인 해 주세요.')
            setEmailValid(false)
        }
    }
    const completeAddressHandler = (data: Address) => {
        setFormData({ ...formData, zipcode: data.zonecode, address: data.address })
        modalContext.activeModalHandler(null)
    }
    const searchAddressHandler = () => {
        //주소 검색
        modalContext.activeModalHandler(<DaumPostcode onComplete={completeAddressHandler} />)
    }

    return (
        <Layout title='회원가입'>
            <div className='container'>
                <form onSubmit={submitFormHandler} className='form'>
                    <div className='wrapper auth-list'>
                        {authList.map((auth, index) => {
                            return (
                                <label key={index}>
                                    <input
                                        type='radio'
                                        name='auth'
                                        value={auth.auth}
                                        checked={formData.auth == auth.auth}
                                        onChange={changeInputHandler}
                                    />
                                    {auth.name}
                                </label>
                            )
                        })}
                    </div>
                    {messageContainer}
                    {formData.auth == 3 ? (
                        <div className='wrapper'>
                            <label className='label'>구분</label>
                            <select onChange={changeInputHandler} name='type' value={formData.type}>
                                <option value={0}>선택</option>
                                {serviceTypes.map((serviceType, index) => {
                                    return (
                                        <option key={index} value={serviceType.id}>
                                            {serviceType.category_name}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    ) : null}
                    {/* <div className='wrapper'>
                        <label className='label'>아이디</label>
                        <input type='text' name='id' defaultValue={formData.id} onChange={changeInputHandler} />
                        {idValid ? null : (
                            <button type='button' onClick={idValidCheckHandler} className='button normal'>
                                중복검사
                            </button>
                        )}
                    </div> */}
                    <div className='wrapper'>
                        <label className='label'>이메일</label>
                        <input type='email' name='email' value={formData.email} onChange={changeInputHandler} />
                        {!emailValid ? (
                            <button type='button' onClick={sendEmailValidHandler} className='button normal'>
                                이메일인증
                            </button>
                        ) : null}
                    </div>
                    {emailValidKey && (
                        <div className='wrapper'>
                            <label className='label'>인증번호</label>
                            <input
                                type='text'
                                name='emailValid'
                                value={formData.emailValid}
                                onChange={changeInputHandler}
                            />
                            <button type='button' onClick={emailValidCheckHandler} className='button normal'>
                                인증
                            </button>
                        </div>
                    )}
                    <div className='wrapper'>
                        <label className='label'>비밀번호</label>
                        <input
                            type='password'
                            name='password'
                            value={formData.password}
                            onChange={changeInputHandler}
                            autoComplete='off'
                        />
                        <p className='tool-tip'>8자리~15자리 영문 , 숫자 , 특수기호(!@#$%^*+=-) 입력 가능</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>비밀번호 확인</label>
                        <input
                            type='password'
                            name='passwordConfirm'
                            value={formData.passwordConfirm}
                            onChange={changeInputHandler}
                            autoComplete='off'
                        />
                        <p className='tool-tip'>8자리~15자리 영문 , 숫자 , 특수기호(!@#$%^*+=-) 입력 가능</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>이름</label>
                        <input type='text' name='name' value={formData.name} onChange={changeInputHandler} />
                    </div>

                    <div className='wrapper'>
                        <label className='label'>핸드폰번호</label>
                        <input type='text' name='phone' value={formData.phone} onChange={changeInputHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>우편번호</label>
                        <input
                            type='text'
                            name='zipcode'
                            value={formData.zipcode}
                            onChange={changeInputHandler}
                            onClick={searchAddressHandler}
                            readOnly={true}
                        />
                        <button type='button' onClick={searchAddressHandler} className='button normal'>
                            주소검색
                        </button>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>주소</label>
                        <input
                            type='text'
                            name='address'
                            value={formData.address}
                            onChange={changeInputHandler}
                            onClick={searchAddressHandler}
                            readOnly={true}
                        />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>상세주소</label>
                        <input
                            type='text'
                            name='addressDetail'
                            value={formData.addressDetail}
                            onChange={changeInputHandler}
                        />
                    </div>

                    {formData.auth == 4 ? (
                        <AdminSignUpForm formData={formData} changeHandler={changeInputHandler} />
                    ) : formData.auth == 3 ? (
                        <ManagerSignUpForm formData={formData} changeHandler={changeInputHandler} />
                    ) : (
                        <UserSignUpForm formData={formData} changeHandler={changeInputHandler} />
                    )}
                    {messageContainer}
                    <div className='buttons center'>
                        <button type='submit' className='primary'>
                            회원가입
                        </button>
                        <Link className='error' to={'/'}>
                            취소
                        </Link>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default SignUpForm

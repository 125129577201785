import React, { FC, useEffect, useState } from 'react'
import GlobalContext from '../contexts/GlobalContext'
import { GlobalType } from '../types'
import useFetch from '../hooks/useFetch'

const GlobalProvider: FC<React.HTMLProps<any>> = (props) => {
    const [load, setLoad] = useState<boolean>(false)
    const [fetchLoad, setFetchLoad] = useState<boolean>(false)
    const { doFetchJson } = useFetch()
    const fetchLoading = (isLoad: boolean) => {
        setFetchLoad(isLoad)
    }
    const [globalValue, setGlobalValue] = useState<GlobalType>({
        authEnum: [],
        registItems: [],
        serviceCategory: [],
        schedule: [],
        config: {},
    })

    useEffect(() => {
        doFetchJson('/config', 'GET', null, (data) => {
            setGlobalValue({
                schedule: data.schedule,
                authEnum: data.auth,
                registItems: data.agree,
                serviceCategory: data.service,
                config: data.config,
            })
            setLoad(true)
        })
    }, [])

    return <GlobalContext.Provider value={{ globalValue, setGlobalValue, load, fetchLoad, fetchLoading }}>{props.children}</GlobalContext.Provider>
}

export default GlobalProvider

import { createContext } from 'react'
import { UserContextType } from '../types'
import { User } from '../types/user'

const defualtUser: User = {
    auth: 0,
    authId: '',
    phone: '',
    email: '',
    id: 0,
    name: '',
    zipcode: '',
    address: '',
    addressDetail: '',
    companyName: '',
    level: 0,
}
const UserContext = createContext<UserContextType>({
    user: defualtUser,
    updateUser: () => {},
    removeUser: () => {},
})

export default UserContext

import { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { Layout } from '../../layout'
import { ServiceRegistDataType } from '../../types/service'
import { GlobalContext, ModalContext, UserContext } from '../../contexts'
import DaumPostcode, { Address } from 'react-daum-postcode'
import { Link } from 'react-router-dom'
import { ServiceCategoryType } from '../../types'
import DateFormat from '../../utils/DateFormat'
import { isHoliday } from 'korean-business-day'

import atList from '../../json/at.json'
import payList from '../../json/pay.json'
import RegExp from '../../utils/RegExp'
type Props = {
    formData: ServiceRegistDataType
    submitHandler: (e: React.FormEvent) => void
    setData: (data: ServiceRegistDataType) => void
    messageContainer: ReactNode
    fileUploadContainer: ReactNode
    includeHoliDay: boolean
}
const ServiceRegistForm: FC<Props> = ({
    formData,
    submitHandler,
    setData,
    messageContainer,
    fileUploadContainer,
    includeHoliDay,
}) => {
    const userContext = useContext(UserContext)
    const globalContext = useContext(GlobalContext)
    const modalContext = useContext(ModalContext)

    const plus3Date = new Date()
    plus3Date.setDate(plus3Date.getDate() + 3)

    const user = userContext.user

    const globalCategory: any[] = globalContext.globalValue.serviceCategory

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if (e.target.name == 'categoryDepth1') {
            if (globalCategory.find((category) => category.id == e.target.value).enabled != 1) {
                alert('준비중인 서비스입니다.')
                return
            }
        }
        if (e.target.name == 'phone') {
            setData({ ...formData, [e.target.name]: RegExp.replaceContact(e.target.value) })
        } else {
            setData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const onComplateHandler = (address: Address) => {
        setData({ ...formData, zipcode: address.zonecode, address: address.address })
        modalContext.activeModalHandler(null)
    }
    const searchAddressHandler = () => {
        modalContext.activeModalHandler(<DaumPostcode onComplete={onComplateHandler} />)
    }

    // const [secondCategory, setSecondCategory] = useState<ServiceCategoryType[]>([])

    // useEffect(() => {
    //     setSecondCategory(
    //         globalCategory.filter((category) => {
    //             return category.type == 1 && category.depth == 2 && category.parent_id == formData.categoryDepth1
    //         })
    //     )
    // }, [formData.categoryDepth1])

    // const [includeHoliDay, setIncludeHoliDay] = useState(false)
    useEffect(() => {}, [formData.serviceDate1, formData.serviceDate2, formData.serviceDate3])

    useEffect(() => {
        let cost: number = 0
        // setIncludeHoliDay(false)
        if (formData.categoryDepth2 == 4) {
            if (
                isHoliday(new Date(formData.serviceDate1)) ||
                isHoliday(new Date(formData.serviceDate2)) ||
                isHoliday(new Date(formData.serviceDate3))
            ) {
                // setIncludeHoliDay(true)
                cost = globalContext.globalValue.config['travel_cost_holiday']
            } else {
                cost = globalContext.globalValue.config['travel_cost']
            }
        }
        setData({ ...formData, travelCost: cost })
    }, [formData.categoryDepth2, formData.serviceDate1, formData.serviceDate2, formData.serviceDate3])

    return (
        <Layout active='service1'>
            <div className='container'>
                <form className='form' onSubmit={submitHandler}>
                    <div className='wrapper'>
                        <label className='label'>이메일</label>
                        <span>{user.email}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>대분류</label>
                        <select name='categoryDepth1' value={formData.categoryDepth1} onChange={onChangeHandler}>
                            {globalCategory
                                .filter((item) => {
                                    return item.type == 1 && item.depth == 1
                                })
                                .map((item: ServiceCategoryType, index: number) => {
                                    return (
                                        <option value={item.id} key={item.id}>
                                            {item.category_name} {!item.enabled && '(준비중)'}
                                        </option>
                                    )
                                })}
                        </select>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>중분류</label>
                        <select name='categoryDepth2' value={formData.categoryDepth2} onChange={onChangeHandler}>
                            <option value={0}>선택</option>
                            {globalCategory
                                .filter((item) => {
                                    return item.type == 2 && item.depth == 2 && item.parent_id == 0
                                })
                                .map((item: ServiceCategoryType, index: number) => {
                                    return (
                                        <option value={item.id} key={item.id}>
                                            {item.category_name}
                                        </option>
                                    )
                                })}
                        </select>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>소분류</label>
                        <select name='categoryDepth3' value={formData.categoryDepth3} onChange={onChangeHandler}>
                            <option value={0}>선택</option>
                            {globalCategory
                                .filter((item) => {
                                    return (
                                        item.type == 2 && item.depth == 3 && item.parent_id == formData.categoryDepth2
                                    )
                                })
                                .map((item: ServiceCategoryType, index: number) => {
                                    return (
                                        <option value={item.id} key={item.id}>
                                            {item.category_name}
                                        </option>
                                    )
                                })}
                        </select>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>기업명</label>
                        <input type='text' name='companyName' value={formData.companyName} onChange={onChangeHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>부서명</label>
                        <input type='text' name='teamName' value={formData.teamName} onChange={onChangeHandler} />
                    </div>
                    <div className='wrapper item'>
                        <label className='label'>우편 번호</label>
                        <input
                            type='text'
                            name='zipcode'
                            value={formData.zipcode}
                            onChange={onChangeHandler}
                            readOnly={true}
                            onClick={searchAddressHandler}
                        />
                        <button type='button' onClick={searchAddressHandler}>
                            주소검색
                        </button>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>주소</label>
                        <input
                            type='text'
                            name='address'
                            value={formData.address}
                            onChange={onChangeHandler}
                            onClick={searchAddressHandler}
                            readOnly={true}
                        />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>상세 주소</label>
                        <input
                            type='text'
                            name='addressDetail'
                            value={formData.addressDetail}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>담당자 명</label>
                        <input type='text' name='name' value={formData.name} onChange={onChangeHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>담당자 폰번호</label>
                        <input type='text' name='phone' value={formData.phone} onChange={onChangeHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>접수 제목</label>
                        <input
                            type='text'
                            name='title'
                            value={formData.title}
                            onChange={onChangeHandler}
                            maxLength={60}
                        />
                    </div>
                    <div className='wrapper'>
                        <label className='label ta'>요청사항</label>
                        <textarea name='content' value={formData.content} onChange={onChangeHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>파일첨부</label>
                        {fileUploadContainer}
                    </div>
                    <div className='wrapper item'>
                        <label className='label'>요청일자 1</label>
                        <input
                            type='date'
                            name='serviceDate1'
                            value={formData.serviceDate1}
                            onChange={onChangeHandler}
                            min={DateFormat.Date(plus3Date)}
                        />
                        {formData.serviceDate1 && (
                            <div className='switch'>
                                {atList.map((at, index) => {
                                    return (
                                        <label key={index}>
                                            <input
                                                type='radio'
                                                name='serviceDate1At'
                                                defaultChecked={formData.serviceDate1At == at.at}
                                                value={at.at}
                                                onChange={onChangeHandler}
                                            />
                                            {at.name}
                                        </label>
                                    )
                                })}
                            </div>
                        )}
                    </div>

                    <div className='wrapper item'>
                        <label className='label'>요청일자 2</label>
                        <input
                            type='date'
                            name='serviceDate2'
                            value={formData.serviceDate2}
                            onChange={onChangeHandler}
                            min={DateFormat.Date(plus3Date)}
                        />
                        {formData.serviceDate2 && (
                            <div className='switch'>
                                {atList.map((at, index) => {
                                    return (
                                        <label key={index}>
                                            <input
                                                type='radio'
                                                name='serviceDate2At'
                                                checked={formData.serviceDate2At == at.at}
                                                value={at.at}
                                                onChange={onChangeHandler}
                                            />
                                            {at.name}
                                        </label>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                    <div className='wrapper item'>
                        <label className='label'>요청일자 3</label>
                        <input
                            type='date'
                            name='serviceDate3'
                            value={formData.serviceDate3}
                            onChange={onChangeHandler}
                            min={DateFormat.Date(plus3Date)}
                        />
                        {formData.serviceDate3 && (
                            <div className='switch'>
                                {atList.map((at, index) => {
                                    return (
                                        <label key={index}>
                                            <input
                                                type='radio'
                                                name='serviceDate3At'
                                                checked={formData.serviceDate3At == at.at}
                                                value={at.at}
                                                onChange={onChangeHandler}
                                            />
                                            {at.name}
                                        </label>
                                    )
                                })}
                            </div>
                        )}
                    </div>

                    <div className='wrapper'>
                        <label className='label'>결제수단</label>
                        <div className='switch input'>
                            {payList.map((pay, index) => {
                                return (
                                    <label key={index}>
                                        <input
                                            type='radio'
                                            name='payType'
                                            checked={formData.payType == pay.pay}
                                            value={pay.pay}
                                            onChange={onChangeHandler}
                                        />
                                        {pay.name}
                                    </label>
                                )
                            })}
                        </div>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>출장비</label>
                        <span>
                            <span
                                style={{ width: 'auto' }}
                                className={`${
                                    formData.travelCost !== 0 && !globalContext.globalValue.config['apply_travel_cost']
                                        ? 'strike'
                                        : ''
                                }`}
                            >
                                {formData.travelCost} 원
                            </span>
                            {!globalContext.globalValue.config['apply_travel_cost'] && formData.travelCost !== 0 ? (
                                <span
                                    style={{
                                        color: 'red',
                                        fontSize: 13,
                                        marginLeft: 8,
                                    }}
                                >
                                    출장비 전액할인
                                </span>
                            ) : null}
                            {includeHoliDay && (
                                <b
                                    style={{
                                        color: 'red',
                                        fontSize: 13,
                                        marginLeft: 8,
                                    }}
                                >
                                    주말 및 공휴일 포함
                                </b>
                            )}
                        </span>
                    </div>
                    {messageContainer}

                    <ul className='tip' style={{ textAlign: 'start' }}>
                        <li>출장비는 휴일 및 주말에 요청할 경우 2배가 적용됩니다. (결제는 현금 및 카드결제 가능)</li>
                        <li>
                            출장비는 방문 후 처리 결과에 따라 기술료 및 부대비용(자재 및 기타)이 포함되어, 견적단가
                            최종금액 청구됩니다.
                        </li>
                        <li>우도,추자도,가파도,마라도등 도서산간지역은 별도의 출장비가 책정됩니다.(협의사항)</li>
                        <li>단, 작업이 단순 처리인 경우는 별도 추가비용 없음.(공지사항 참조)</li>
                    </ul>
                    <div className='buttons center'>
                        <button type='submit' className='primary'>
                            작성완료
                        </button>
                        <Link to={'/'} className='error'>
                            취소
                        </Link>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default ServiceRegistForm

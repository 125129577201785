import React, { useContext, useState } from 'react'
import ModalContext from '../../contexts/ModalContext'
import authList from '../../json/auth.json'
import useFetch from '../../hooks/useFetch'
import RegExp from '../../utils/RegExp'
const FiindEmailModal = () => {
    const modalContext = useContext(ModalContext)

    const [findId, setFindId] = useState<string>('')
    const { doFetchJson } = useFetch()
    const submitFormHandle = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!formData.name) {
            setMessage('이름을 입력 해 주세요.')
            return
        }
        if (!formData.phone) {
            setMessage('연락처를 입력 해 주세요.')
            return
        }
        doFetchJson(
            '/auth/findEmail',
            'POST',
            JSON.stringify(formData),
            (data) => {
                setFindId(data.id)
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        auth: parseInt(localStorage.getItem('auth') ?? '1'),
    })
    const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value
        if (e.target.name === 'phone') {
            value = RegExp.replaceContact(value)
        }
        setFormData({ ...formData, [e.target.name]: value })
    }
    const [message, setMessage] = useState('')

    const closeModalHandle = () => {
        modalContext.activeModalHandler(null)
    }

    const findForm = (
        <div className='container'>
            <form className='form' onSubmit={submitFormHandle}>
                <div className='wrapper auth-list'>
                    {authList.map((auth, index) => {
                        return (
                            <label key={index}>
                                <input
                                    type='radio'
                                    name='auth'
                                    value={auth.auth}
                                    defaultChecked={formData.auth === auth.auth}
                                    onChange={changeInputHandler}
                                />
                                {auth.name}
                            </label>
                        )
                    })}
                </div>
                <div className='wrapper'>
                    <label className='label'>이름</label>
                    <input type='text' name='name' value={formData.name} onChange={changeInputHandler} />
                </div>
                <div className='wrapper'>
                    <label className='label'>연락처</label>
                    <input type='text' name='phone' value={formData.phone} onChange={changeInputHandler} />
                </div>
                <div className='message-container'>
                    <span>{message}</span>
                </div>
                <div className='buttons right'>
                    <button type='submit' className='primary'>
                        찾기
                    </button>
                    <button type='button' className='error' onClick={closeModalHandle}>
                        닫기
                    </button>
                </div>
            </form>
        </div>
    )
    const resultPage = (
        <div className='container'>
            <div className='result-message'>고객님의 이메일은 {findId} 입니다.</div>
            <div className='buttons center'>
                <button type='button' className='primary' onClick={closeModalHandle}>
                    확인
                </button>
            </div>
        </div>
    )

    return findId === '' ? findForm : resultPage
}

export default FiindEmailModal

import logoImage from '../images/logo.png'
import mark1 from '../images/mark_1.jpg'
import { Link } from 'react-router-dom'
import { GlobalContext, ModalContext } from '../contexts'
import { useContext } from 'react'
import asProcess from '../images/process.jpeg'
import checkProcess from '../images/process2.png'
const Header = () => {
    const { config } = useContext(GlobalContext).globalValue
    const modalContext = useContext(ModalContext)
    const globalAsProcessGuideHandelr = () => {
        modalContext.activeModalHandler(
            <div className='container'>
                <img src={asProcess} style={{ width: '80vw', maxHeight: '80vh', objectFit: 'contain' }} />
            </div>,
            'A/S 서비스 절차'
        )
    }
    const globalCheckProcessGuideHandler = () => {
        modalContext.activeModalHandler(
            <div className='container'>
                <img src={checkProcess} style={{ width: '80vw', maxHeight: '80vh', objectFit: 'contain' }} />
            </div>,
            '유지관리•성능점검 진행순서'
        )
    }
    return (
        <>
            <header>
                <div className='blank'></div>
                <div>
                    <Link to={'/'}>
                        <img src={logoImage} alt='logo' />
                    </Link>
                </div>
                <div className='mark'>
                    <img src={mark1} alt='mark' />
                </div>
            </header>

            <div className='global_guide'>
                <h1>
                    <span>제주 전지역 정보통신 경정비(A/S), </span>
                    <span>유지보수, 유지관리, 성능점검, 렌탈서비스 신청</span>
                </h1>
                <div className='contact'>
                    <p>
                        <span>
                            고객센터 <a href={`tel:${config.contact}`}>{config.contact}</a>
                        </span>
                        <span>(09~18시 , 주말,공휴일제외)</span>
                    </p>

                    <p>
                        <span>공사•물품견적의뢰/업무제휴/홍보제안</span>{' '}
                        <span>
                            <a href={`mailto:${config.email}`}>{config.email}</a>
                        </span>
                    </p>
                </div>
                <div className='buttons'>
                    <button onClick={globalAsProcessGuideHandelr} className='error'>
                        경정비(A/S) 및 방문점검 진행절차 둘러보기
                    </button>
                    <button onClick={globalCheckProcessGuideHandler} className='info'>
                        유지관리비•성능점검 진행절차 둘러보기
                    </button>
                </div>
                <div className='family-sites'>
                    <label>
                        전국 비투비서비스 사이트
                        <select>
                            <option value={0}>지역업체 모집중</option>
                            <option value={'b2bbusan.com'}>비투비부산(b2bbusan.com)</option>
                            <option value={'b2bchungbuk.com'}>비투비충북(b2bchungbuk.com)</option>
                            <option value={'b2bchunanam.com'}>비투비충남(b2bchungnam.com)</option>
                            <option value={'b2bdaegu.com'}>비투비대구(b2bdaegu.com)</option>
                            <option value={'b2bdaejeon.com'}>비투비대전(b2bdaejeon.com)</option>
                            <option value={'b2bgangwon.com'}>비투비강원(b2bgangwon.com)</option>
                            <option value={'b2bgwangju.com'}>비투비광주(b2bgwangju.com)</option>
                            <option value={'b2bgyeonggi.com'}>비투비경기(b2bgyeonggi.com)</option>
                            <option value={'b2bincheon.com'}>비투비인천(b2bincheon.com)</option>
                            <option value={'b2bjeonbuk.com'}>비투비전북(b2bjeonbuk.com)</option>
                            <option value={'b2bjeonnam.com'}>비투비전남(b2bjeonnam.com)</option>
                            <option value={'b2bkyungbuk.com'}>비투비경북(b2bkyungbuk.com)</option>
                            <option value={'b2bkyungnam.com'}>비투비경남(b2bkyungnam.com)</option>
                            <option value={'b2bsejong.com'}>비투비세종(b2bsejong.com)</option>
                            <option value={'b2bseoul.com'}>비투비서울(b2bseoul.com)</option>
                            <option value={'b2bulsan.com'}>비투비울산(b2bulsan.com)</option>
                        </select>
                    </label>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <th>[서비스 대상]</th>
                            <td>관공서 / 일반기업 / 학교 / 아파트 / 병원 / 호텔 및 기타업종</td>
                        </tr>
                        <tr>
                            <th>[서비스 업무]</th>
                            <td>
                                운영중인 CCTV설비 / 주차관제설비 / 전관방송설비 / AV설비 / 기타 통신장비일체 및 통신공사
                                관련 A/S와 유지보수
                            </td>
                        </tr>
                        <tr>
                            <th>[서비스 증상]</th>
                            <td>
                                ex. 낙뢰로 인한 CCTV불량 / CCTV화면不 / 네트워크 신호불량 / 스피커 소리不 / 비상방송이
                                송출不 / 주차차단바 보수 / 루프코일 교체 / 출차주의등, 경광등 신규 및 교체 / 통신케이블
                                신규포설 및 공사 / 신규장비구매 및 관급자재 설계
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Header

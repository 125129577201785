import React, { FC } from 'react'
import { SignUpFormType } from '../../types/auth'
type Props = {
    formData: SignUpFormType
    changeHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}
const ManagerSignUpForm: FC<Props> = ({ formData, changeHandler }) => {
    return (
        <>
            <div className='wrapper'>
                <label className='label'>가맹점명</label>
                <input type='text' name='companyName' value={formData.companyName} onChange={changeHandler} />
            </div>
            <div className='wrapper'>
                <label className='label'>대표자</label>
                <input type='text' name='ceoName' value={formData.ceoName} onChange={changeHandler} />
            </div>
            <div className='wrapper'>
                <label className='label'>사업자등록번호</label>
                <input type='text' name='businessNumber' value={formData.businessNumber} onChange={changeHandler} />
            </div>
            <div className='wrapper'>
                <label className='label'>업종면허번호</label>
                <input type='text' name='licenseNumber' value={formData.licenseNumber} onChange={changeHandler} />
            </div>
        </>
    )
}

export default ManagerSignUpForm

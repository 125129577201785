import { Fragment, useContext, useEffect, useState } from 'react'
import UserContext from './contexts/UserContext'
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Main, SignIn, SignUp } from './pages'
import Mypage from './pages/user/Mypage'
import GlobalContext from './contexts/GlobalContext'
import Secession from './pages/user/Secession'
import UpdateUser from './pages/user/UpdateUser'
import ServiceRegist from './pages/service/ServiceRegist'
import PayResult from './pages/pay/PayResult'
import BoardView from './pages/board/BoardView'
import BoadrWrite from './pages/board/BoadrWrite'
import BoardUpdate from './pages/board/BoardUpdate'
import BoardList from './pages/board/BoardList'
import BoardDelete from './pages/board/BoardDelete'
import UserList from './pages/user/UserList'
import SubPage from './pages/common/SubPage'
import Loading from './pages/Loading'
import ScrollToTop from './components/ScrollToTop'
import ServiceView from './pages/service/ServiceView'
import ServiceList from './pages/service/ServiceList'
import InitPath from './components/InitPath'
import Config from './pages/common/Config'
import AccessUser from './pages/auth/AccessUser'
import { ModalContext } from './contexts'
import { useCookies } from 'react-cookie'
import PopUpLayer from './components/PopUpLayer'

const AppRouter = () => {
    const userContext = useContext(UserContext)
    const globalContext = useContext(GlobalContext)

    // const { activeModalHandler } = useContext(ModalContext)
    // useEffect(() => {
    //     activeModalHandler()
    // }, [])
    const certifiedRouter = (
        <>
            <Route path='/' element={<Main />} />
            <Route path='/my' element={<Mypage />} />
            <Route path='/my/secession' element={<Secession />} />

            <Route path='/service' element={<ServiceList />} />
            <Route path='/service/view/:serviceId' element={<ServiceView />} />
            <Route path='/service/write' element={<ServiceRegist />} />

            <Route path='/pay/result/success/:serviceId' element={<PayResult result={true} />} />
            <Route path='/pay/result/failure/:serviceId' element={<PayResult result={false} />} />

            <Route path='/board/:boardType' element={<BoardList />} />
            <Route path='/board/:boardType/write' element={<BoadrWrite />} />
            <Route path='/board/:boardType/view/:boardId' element={<BoardView />} />
            <Route path='/board/:boardType/update/:boardId' element={<BoardUpdate />} />
            <Route path='/board/:boardType/delete/:boardId' element={<BoardDelete />} />

            <Route path='/user/:level' element={<UserList />} />
            <Route path='/user/:userAuth/update/:userId' element={<UpdateUser />} />

            <Route path='/sub/:sub' element={<SubPage />} />
            <Route path='/config' element={<Config />} />
            <Route path='*' element={<Main />} />
        </>
    )

    const unCertifiedRouter = (
        <>
            <Route path='*' element={<SignIn />} />
            <Route path='/signUp' element={<SignUp />} />
            <Route path='/sub/:sub' element={<SubPage />} />
            <Route path='/admin/accessUser' element={<AccessUser />} />
            <Route path='/board/:boardType' element={<BoardList />} />
            <Route path='/board/:boardType/view/:boardId' element={<BoardView />} />
        </>
    )
    if (!globalContext.load) {
        return <Loading />
    }

    return (
        <BrowserRouter>
            <ScrollToTop />
            <InitPath />

            <Routes>{userContext.user.auth === 0 ? unCertifiedRouter : certifiedRouter}</Routes>
            <PopUpLayer />
        </BrowserRouter>
    )
}

export default AppRouter

import { FC, useContext, useEffect, useState } from 'react'

import { Link, useLocation, useParams } from 'react-router-dom'
import { UserContext } from '../contexts'
import menuList from '../json/menu.json'

type Props = {
    active?: string
}
const Navigation: FC<Props> = ({ active }) => {
    const userContext = useContext(UserContext)
    const user = userContext.user
    const params = useParams()
    const location = useLocation()
    const menus = menuList.find((menu) => menu.level == user.level)?.menus

    return (
        <div className='navigation'>
            {menus?.map((menu, index) => {
                return (
                    <Link to={menu.link} key={index} className={active == menu.type ? 'active' : ''}>
                        {menu.name}
                    </Link>
                )
            })}
        </div>
    )
}

export default Navigation

import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Layout } from '../../layout'
import { ModalContext, UserContext } from '../../contexts'
import ServiceAdmin from './ServiceAdmin'
import ServiceManager from './ServiceManager'
import ServiceUser from './ServiceUser'
import ReviewWrite from '../../components/ReviewWrite'
import useFetch from '../../hooks/useFetch'
import Loading from '../Loading'

const ServiceView = () => {
    const { serviceId } = useParams()
    const modalContext = useContext(ModalContext)
    const userContext = useContext(UserContext)
    const { user } = userContext
    const [item, setItem] = useState(null)
    const [managerList, setManagerList] = useState<any[]>([])
    const { doFetchJson } = useFetch()
    const navigate = useNavigate()
    useEffect(() => {
        doFetchJson('/user/managerList', 'GET', null, (data) => {
            setManagerList(data.manager)
        })
    }, [])
    useEffect(() => {
        setItemHandler()
    }, [])

    const setItemHandler = () => {
        doFetchJson(
            `/service/view/${serviceId}?userId=${user.id}&userAuth=${user.auth}`,
            'GET',
            null,
            (data) => {
                setItem(data.item)
            },
            (message) => {
                alert(message)
                navigate('/service')
            }
        )
    }
    const reviewHandler = () => {
        //만족도조사
        modalContext.activeModalHandler(<ReviewWrite serviceItem={item} finishEvent={setItemHandler} />, '만족도 조사')
    }
    if (item == null) {
        return <Loading />
    } else {
        if (user.auth == 4) {
            return <ServiceAdmin item={item} managerList={managerList} setItemHandler={setItemHandler} />
        } else if (user.auth == 3) {
            return <ServiceManager item={item} setItemHandler={setItemHandler} reviewHandler={reviewHandler} />
        } else {
            return <ServiceUser item={item} reviewHandler={reviewHandler} setItemHandler={setItemHandler} />
        }
    }
}

export default ServiceView

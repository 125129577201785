import { ReactNode, useState } from 'react'

const useMessage = (defaultMessage?: string): [React.Dispatch<React.SetStateAction<string>>, ReactNode] => {
    const [message, setMessage] = useState<string>(defaultMessage ?? '')

    const messageContainer: ReactNode = (
        <div className='message-container'>
            <span>{message}</span>
        </div>
    )

    return [setMessage, messageContainer]
}

export default useMessage

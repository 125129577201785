import { useContext, useState } from 'react'
import { GlobalContext } from '../contexts'

const useFetch = () => {
    const { fetchLoading } = useContext(GlobalContext)

    const doFetchJson = (
        url: string,
        method?: string,
        fetchBody?: string | null,
        successCallback?: (data: any) => void,
        failureCallback?: (message: string) => void
    ) => {
        fetchLoading(true)
        const fetchOptions: RequestInit = {
            method: method ?? 'GET',
            headers: {
                'content-type': 'application/json',
            },
        }
        if (fetchBody) {
            fetchOptions.body = fetchBody
        }

        fetch(`${process.env.REACT_APP_URL}${url}`, fetchOptions)
            .then((response) => {
                if (!response.ok) {
                    alert('통신 에러입니다. 새로고침 후 다시 시도해주세요.')
                    console.log(response)
                    return null
                }
                return response.json()
            })
            .then((json: any) => {
                if (json.result) {
                    if (successCallback) {
                        successCallback(json.data)
                    }
                } else {
                    if (failureCallback) {
                        failureCallback(json.message)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                fetchLoading(false)
            })
    }
    const doFetchForm = (
        url: string,
        method?: string,
        fetchBody?: any,
        successCallback?: (data: any) => void,
        failureCallback?: (message: string) => void
    ) => {
        fetchLoading(true)
        const fetchOptions: RequestInit = {
            method: method ?? 'GET',
        }
        if (fetchBody) {
            fetchOptions.body = fetchBody
        }

        fetch(`${process.env.REACT_APP_URL}${url}`, fetchOptions)
            .then((response) => {
                if (!response.ok) {
                    alert('통신 에러입니다. 새로고침 후 다시 시도해주세요.')
                    console.log(response)
                    return null
                }
                return response.json()
            })
            .then((json: any) => {
                if (json.result) {
                    if (successCallback) {
                        successCallback(json.data)
                    }
                } else {
                    if (failureCallback) {
                        failureCallback(json.message)
                    }
                }
            })
            .finally(() => {
                fetchLoading(false)
            })
    }

    return { doFetchJson, doFetchForm }
}
export default useFetch

import React, { useContext } from 'react'
import GlobalContext from '../contexts/GlobalContext'
import ModalContext from '../contexts/ModalContext'
import { RegistItemType } from '../types'

type Props = {}
const AgreeItems = () => {
    const globalContext = useContext(GlobalContext)
    const modalContext = useContext(ModalContext)

    const clickAgreementHandle = (item: RegistItemType) => {
        modalContext.activeModalHandler(
            <div className='sub-content' dangerouslySetInnerHTML={{ __html: item.content }}></div>,
            item.title
        )
    }
    return (
        <div className='agreement'>
            <div className='content'>
                <h5>필수 동의항목</h5>
                <div>
                    <ul>
                        {globalContext?.globalValue.registItems.map((item, index) => {
                            const requiredText = item.required ? '필수' : '선택'
                            if (item.type != 1) return null
                            return (
                                <li key={index}>
                                    <label>
                                        <input type='checkbox' required={item.required} />
                                        {item.title}({requiredText})
                                    </label>
                                    <button type='button' onClick={() => clickAgreementHandle(item)}>
                                        전문보기
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AgreeItems

import { useContext, useEffect, useState } from 'react'
import { GlobalContext, UserContext } from '../../contexts'
import ServiceRegistForm from './ServiceRegistForm'
import ServiceRegistResult from './ServiceRegistResult'
import { ServiceRegistDataType } from '../../types/service'
import { useMessage } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import useFileUploader from '../../hooks/useFileUploader'
import useFetch from '../../hooks/useFetch'
import { isHoliday } from 'korean-business-day'

const ServiceRegist = () => {
    const userContext = useContext(UserContext)
    const user = userContext.user
    const navigate = useNavigate()
    const [formData, setFormData] = useState<ServiceRegistDataType>({
        categoryDepth1: 1,
        categoryDepth2: 0,
        categoryDepth3: 0,
        companyName: user.companyName,
        teamName: '',
        zipcode: user.zipcode,
        address: user.address,
        addressDetail: user.addressDetail,
        name: user.name,
        phone: user.phone,
        email: user.email,
        title: '',
        content: '',
        serviceDate1: '',
        serviceDate1At: 1,
        serviceDate2: '',
        serviceDate2At: 1,
        serviceDate3: '',
        serviceDate3At: 1,
        travelCost: 0,
        userId: user.id,
        // files: [],
        payType: 1,
    })
    const [files, fileUploadContainer, fileUploadResultContainer] = useFileUploader({
        maxFileCount: 5,
    })

    const [step, setStep] = useState<number>(0)
    const registFormSubmitHandler = (e: React.FormEvent) => {
        setMessage('')
        e.preventDefault()
        if (!formData.categoryDepth1) {
            setMessage('대분류를 선택 해 주세요.')
            return
        }
        if (!formData.categoryDepth2) {
            setMessage('중분류를 선택 해 주세요.')
            return
        }
        if (!formData.categoryDepth3) {
            setMessage('소분류를 선택 해 주세요.')
            return
        }
        if (!formData.companyName) {
            setMessage('기업명을 입력 해 주세요.')
            return
        }
        if (!formData.teamName) {
            setMessage('부서명을 입력 해 주세요.')
            return
        }
        if (!formData.zipcode || !formData.address || !formData.addressDetail) {
            setMessage('주소와 상세주소를 입력 해 주세요.')
            return
        }
        if (!formData.name) {
            setMessage('담당자명을 입력 해 주세요.')
            return
        }
        if (!formData.phone) {
            setMessage('담당자 연락처를 입력 해 주세요.')
            return
        }
        if (!formData.title) {
            setMessage('접수 제목을 입력 해 주세요.')
            return
        }
        if (!formData.content) {
            setMessage('요청사항을 입력 해 주세요.')
            return
        }

        const dateList = []
        if (!formData.serviceDate1) {
            setMessage('첫번째 요청 일자를 선택 해 주세요.')
            return
        } else {
            dateList.push(formData.serviceDate1)
        }
        if (!formData.serviceDate2) {
            setMessage('두번째 요청 일자를 선택 해 주세요.')
            return
        } else {
            dateList.push(formData.serviceDate2)
        }
        if (!formData.serviceDate3) {
            setMessage('세번째 요청 일자를 선택 해 주세요.')
            return
        } else {
            dateList.push(formData.serviceDate3)
        }
        if (dateList.length && dateList.length !== new Set(dateList).size) {
            setMessage('중복된 요청일자가 있습니다.')
            return
        }

        setStep(1)
    }

    const [setMessage, messageContainer] = useMessage()
    const { doFetchForm } = useFetch()
    const registCheckSubmitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        const fetchBody = new FormData()
        fetchBody.append('categoryDepth1', formData.categoryDepth1.toString())
        fetchBody.append('categoryDepth2', formData.categoryDepth2.toString())
        fetchBody.append('categoryDepth3', formData.categoryDepth3.toString())
        fetchBody.append('companyName', formData.companyName)
        fetchBody.append('teamName', formData.teamName)
        fetchBody.append('zipcode', formData.zipcode)
        fetchBody.append('address', formData.address)
        fetchBody.append('addressDetail', formData.addressDetail)
        fetchBody.append('name', formData.name)
        fetchBody.append('phone', formData.phone)
        fetchBody.append('email', formData.email)
        fetchBody.append('title', formData.title)
        fetchBody.append('content', formData.content)
        fetchBody.append('serviceDate1', formData.serviceDate1)
        fetchBody.append('serviceDate1At', formData.serviceDate1At.toString())
        fetchBody.append('serviceDate2', formData.serviceDate2)
        fetchBody.append('serviceDate2At', formData.serviceDate2At.toString())
        fetchBody.append('serviceDate3', formData.serviceDate3)
        fetchBody.append('serviceDate3At', formData.serviceDate3At.toString())
        fetchBody.append('travelCost', formData.travelCost.toString())
        fetchBody.append('userId', formData.userId.toString())
        fetchBody.append('payType', formData.payType.toString())

        files.forEach((file) => {
            fetchBody.append('uploadFiles', file, file.name)
        })

        doFetchForm(
            '/service',
            'POST',
            fetchBody,
            () => {
                alert('등록 되었습니다.')
                navigate('/')
            },
            (message) => {
                setMessage(message)
            }
        )
    }

    const setData = (data: ServiceRegistDataType) => {
        setFormData(data)
    }
    const toRegistForm = () => {
        setStep(0)
    }

    const globalContext = useContext(GlobalContext)
    const [includeHoliDay, setIncludeHoliDay] = useState(false)
    useEffect(() => {}, [formData.serviceDate1, formData.serviceDate2, formData.serviceDate3])

    useEffect(() => {
        let cost: number = 0
        setIncludeHoliDay(false)
        if (formData.categoryDepth2 == 4) {
            if (
                isHoliday(new Date(formData.serviceDate1)) ||
                isHoliday(new Date(formData.serviceDate2)) ||
                isHoliday(new Date(formData.serviceDate3))
            ) {
                setIncludeHoliDay(true)
                cost = globalContext.globalValue.config['travel_cost_holiday']
            } else {
                cost = globalContext.globalValue.config['travel_cost']
            }
        }
        setData({ ...formData, travelCost: cost })
    }, [formData.categoryDepth2, formData.serviceDate1, formData.serviceDate2, formData.serviceDate3])

    if (step === 0) {
        return (
            <ServiceRegistForm
                formData={formData}
                submitHandler={registFormSubmitHandler}
                setData={setData}
                messageContainer={messageContainer}
                fileUploadContainer={fileUploadContainer}
                includeHoliDay={includeHoliDay}
            />
        )
    } else {
        return (
            <ServiceRegistResult
                formData={formData}
                submitHandler={registCheckSubmitHandler}
                messageContainer={messageContainer}
                cancleHandler={toRegistForm}
                files={files}
                fileUploadContainer={fileUploadResultContainer}
                includeHoliDay={includeHoliDay}
            />
        )
    }
}

export default ServiceRegist

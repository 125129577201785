import React, { FC } from 'react'
import { SignUpFormType } from '../../types/auth'
type Props = {
    formData: SignUpFormType
    changeHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}
const UserSignUpForm: FC<Props> = ({ formData, changeHandler }) => {
    return (
        <div className='wrapper'>
            <label className='label'>기관명(업체명)</label>
            <input type='text' name='companyName' value={formData.companyName} onChange={changeHandler} />
        </div>
    )
}

export default UserSignUpForm

import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Layout } from '../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import authList from '../../json/auth.json'
import DateFormat from '../../utils/DateFormat'
import useFetch from '../../hooks/useFetch'
import { UserContext } from '../../contexts'
const UpdateUser = () => {
    const { userAuth, userId } = useParams()
    const navigate = useNavigate()

    const [item, setItem] = useState<any>({})
    const [showAccessButton, setShowAccessButton] = useState(false)
    const { user } = useContext(UserContext)
    const [formData, setFormData] = useState<any>({
        description: '',
    })
    const { doFetchJson } = useFetch()
    useEffect(() => {
        doFetchJson(`/user/${userAuth}?userId=${userId}`, 'GET', null, (data) => {
            setItem(data.item)
            setFormData({ ...formData, description: data.item.description })
            setShowAccessButton(data.item.enabled == 0)
        })
    }, [])

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        doFetchJson(
            `/user/${userAuth}`,
            'PUT',
            JSON.stringify({ description: formData.description, userId: item.id }),
            () => {
                alert('등록 되었습니다.')
                navigate(-1)
            }
        )
    }
    const accessHandler = () => {
        doFetchJson(
            '/user/access',
            'PUT',
            JSON.stringify({
                auth: userAuth,
                userId: item.id,
            }),
            () => {
                setItem({ ...item, enabled: 1 })
                setShowAccessButton(false)
            }
        )
    }

    const changeInputHandler = (e: React.ChangeEvent<any>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    let active = ''
    if (userAuth == '4') {
        active = 'admin'
    } else if (userAuth == '3') {
        active = 'manager'
    } else {
        active = 'user'
    }
    return (
        <Layout active={active}>
            <div className='container'>
                <form onSubmit={submitHandler}>
                    <div className='wrapper'>
                        <label className='label'>구분</label>
                        <span>{authList.find((auth) => auth.auth.toString() == userAuth)?.name}</span>
                    </div>
                    {/* <div className='wrapper'>
                        <label className='label'>아이디</label>
                        <span>{item.user_id}</span>
                    </div> */}
                    <div className='wrapper'>
                        <label className='label'>이메일</label>
                        <span>{item.email}</span>
                    </div>
                    {item.company_name && (
                        <div className='wrapper'>
                            <label className='label'>기업명</label>
                            <span>{item.company_name}</span>
                        </div>
                    )}

                    <div className='wrapper'>
                        <label className='label'>이름</label>
                        <span>{item.name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>폰번호</label>
                        <span>{item.phone}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>주소</label>
                        <span>
                            [{item.zipcode}] {item.address} {item.address_detail}
                        </span>
                    </div>
                    {user.level == 4 && (
                        <div className='wrapper'>
                            <label className='label'>승인여부</label>
                            <span>{!showAccessButton ? '승인' : '미승인'}</span>
                            {/* {showAccessButton ? (
                                <button type='button' onClick={accessHandler}>
                                    회원가입 승인
                                </button>
                            ) : (
                                ''
                            )} */}
                        </div>
                    )}

                    {userAuth === '3' && (
                        <Fragment>
                            <div className='wrapper'>
                                <label className='label'>대표자</label>
                                <span>{item.ceo_name}</span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>사업자등록번호</label>
                                <span>{item.business_number}</span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>업종면허번호</label>
                                <span>{item.license_number}</span>
                            </div>
                        </Fragment>
                    )}
                    <div className='wrapper'>
                        <label className='label'>날짜</label>
                        <span>{DateFormat.Date(new Date(Date.now()))}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label ta'>메모</label>
                        <textarea name='description' value={formData.description || ''} onChange={changeInputHandler} />
                    </div>
                    <div className='buttons right'>
                        <button type='submit' className='primary'>
                            등록
                        </button>
                        <button
                            type='button'
                            className='error'
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            목록
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default UpdateUser

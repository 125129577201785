import { FC, useContext, useEffect, useState } from 'react'
import tagList from '../json/tag.json'

import { Link, useNavigate } from 'react-router-dom'
import { UserContext } from '../contexts'
import DateFormat from '../utils/DateFormat'
import boardList from '../json/board.json'
import useFetch from '../hooks/useFetch'
type Props = {
    type: number[]
}
const BoardPreview: FC<Props> = ({ type }) => {
    const [items, setItems] = useState([])
    const userContext = useContext(UserContext)
    const user = userContext.user
    const navigate = useNavigate()

    const [title, setTitle] = useState<string | undefined>('')
    const { doFetchJson } = useFetch()
    const [totalCount, setTotalCount] = useState(0)
    useEffect(() => {
        const showType = type.join(',')

        setTitle(
            type
                .map((t) => {
                    return boardList.find((b) => b.id == t)?.name
                })
                .join('&')
        )
        doFetchJson(
            `/board/${showType}?size=5&page=1&sort=id&order=desc&tag=0&user=${user.id}&auth=${user.auth}`,
            'GET',
            null,
            (data) => {
                setItems(data.items)
                setTotalCount(data.totalCount)
            }
        )
    }, [])

    return (
        <div className='preview list'>
            <div className='header'>
                <h5>{title}</h5>
                <Link to={`/board/${type.join(',')}`}>더보기</Link>
            </div>
            <div className='content'>
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '15%' }}>번호</th>
                            {/* <th style={{ width: '15%' }}>등록No.</th> */}
                            <th style={{ width: '15%' }}>구분</th>
                            <th style={{ width: '30%' }}>제목</th>
                            <th style={{ width: '40%' }}>작성날짜</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.length ? (
                            items.map((item: any, index) => {
                                const numbering = totalCount - (1 - 1) * 5 - index
                                return (
                                    <tr
                                        key={index}
                                        onClick={() => {
                                            navigate(`/board/${item.type}/view/${item.id}`)
                                        }}
                                    >
                                        <td>{numbering}</td>
                                        {/* <td>{item.id}</td> */}
                                        <td>{boardList.find((board) => board.id == item.type)?.name}</td>
                                        <td>{item.title}</td>
                                        <td>{DateFormat.Date(new Date(item.create_at))}</td>
                                    </tr>
                                )
                            })
                        ) : (
                            <tr style={{ backgroundColor: 'initial' }}>
                                <td colSpan={5}>
                                    <div
                                        style={{
                                            height: '2rem',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        조회 된 데이터가 없습니다.
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BoardPreview

import { createContext } from 'react'
import { GlobalContextType } from '../types'

const GlobalContext = createContext<GlobalContextType>({
    globalValue: { authEnum: [], registItems: [], serviceCategory: [], schedule: [], config: {} },
    load: false,
    setGlobalValue: () => {},
    fetchLoad: false,
    fetchLoading: () => {},
})
export default GlobalContext

import { useParams } from 'react-router-dom'
import { Layout } from '../../layout'
import { ReactNode, useContext, useEffect, useState } from 'react'

import { GlobalContext } from '../../contexts'

const SubPage = () => {
    const { sub } = useParams()
    const [content, setContent] = useState<any>(<></>)
    const [title, setTitle] = useState('')
    const globalContext = useContext(GlobalContext)

    useEffect(() => {
        if (sub === 'refund') {
            setTitle('환불정책안내')
            setContent(globalContext.globalValue.registItems.find((item) => item.id == 3)?.content)
        } else if (sub === 'privacy') {
            setTitle('개인정보처리방침')
            setContent(globalContext.globalValue.registItems.find((item) => item.id == 2)?.content)
        } else if (sub === 'terms') {
            setTitle('홈페이지이용약관')
            setContent(globalContext.globalValue.registItems.find((item) => item.id == 1)?.content)
        } else if (sub === 'marketing') {
            setTitle('마케팅 목적의 개인정보 수집 및 이용에 대한 동의')
            setContent(globalContext.globalValue.registItems.find((item) => item.id == 0)?.content)
        }
    }, [sub])

    return (
        <Layout title={title}>
            <div className='container'>
                <div dangerouslySetInnerHTML={{ __html: content }} className='sub-content'></div>
            </div>
        </Layout>
    )
}

export default SubPage

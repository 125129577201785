import React, { FC, useEffect, useState } from 'react'
import UserContext from '../contexts/UserContext'
import { User } from '../types/user'

const UserProvider: FC<React.HTMLProps<any>> = (props) => {
    const [user, setUser] = useState<User>({
        auth: 0,
        authId: '',
        phone: '',
        email: '',
        id: 0,
        level: 0,
        name: '',
        zipcode: '',
        address: '',
        addressDetail: '',
        companyName: '',
    })

    useEffect(() => {
        const sessionUser = sessionStorage.getItem('user') ?? null
        if (sessionUser) {
            setUser(JSON.parse(sessionUser))
        }
    }, [])

    const updateUser = (newUser: User) => {
        sessionStorage.setItem('user', JSON.stringify(newUser))
        setUser(newUser)
    }
    const removeUser = () => {
        sessionStorage.setItem('user', JSON.stringify({ auth: 0, authId: '', contact: '', email: '', id: 0, name: '' }))
        setUser({
            auth: 0,
            authId: '',
            phone: '',
            email: '',
            id: 0,
            name: '',
            zipcode: '',
            address: '',
            addressDetail: '',
            companyName: '',
            level: 0,
        })
    }
    return <UserContext.Provider value={{ user, updateUser, removeUser }}>{props.children}</UserContext.Provider>
}

export default UserProvider

import React, { FC, Fragment, useContext, useEffect, useState } from 'react'
import { ModalContext } from '../contexts'
import { useCookies } from 'react-cookie'
import { Navigation, Pagination } from 'swiper/modules'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
type PopUp = {
    id: number
    src: string
    title: string
    device: 'mobile' | 'desktop'
}
const PopUpLayer: FC = () => {
    const [popupItems, setPopupList] = useState<PopUp[]>([
        { id: 1, src: '/_popup/_241002.jpg', title: '구인공고', device: 'desktop' },
    ])
    const [showPopupList, setShowPopUpList] = useState<PopUp[]>([])
    const [cookies, setCookie, removeCookie] = useCookies()

    useEffect(() => {
        setShowPopUpList(
            popupItems.filter((_popup) => {
                return !cookies[`popup_${_popup.id}`]
            })
        )
    }, [popupItems])

    const closeHandler = (id: number, index: number, weekClose?: boolean) => {
        if (weekClose) {
            const date = new Date()
            date.setDate(date.getDate() + 7)
            setCookie(`popup_${id}`, 1, {
                expires: date,
            })
        }
        setPopupList(
            popupItems.filter((popupItem, popupItemindex) => {
                return popupItemindex != index
            })
        )
    }
    return (
        <Fragment>
            {['mobile', 'desktop'].map((_device, _index) => {
                return (
                    showPopupList.filter((_popup) => {
                        return _popup.device === _device
                    }).length > 0 && (
                        <div className={`popup ${_device}`} key={_index}>
                            <div className='popup-wrapper'>
                                <Swiper
                                    modules={[Pagination]}
                                    slidesPerView={1}
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                    loop
                                >
                                    {showPopupList
                                        .filter((_popup) => {
                                            return _popup.device === _device
                                        })
                                        .filter((popupItem) => {
                                            return !cookies[`popup_${popupItem.id}`]
                                        })
                                        .map((popupItem, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className={`popup-item`}>
                                                        <div className='header'>{popupItem.title}</div>
                                                        <img src={popupItem.src} />
                                                        <div className='controls'>
                                                            <button
                                                                type='button'
                                                                onClick={() => {
                                                                    closeHandler(popupItem.id, index, true)
                                                                }}
                                                            >
                                                                일주일 동안 다시 열람하지 않습니다.
                                                            </button>
                                                            <button
                                                                type='button'
                                                                onClick={() => {
                                                                    closeHandler(popupItem.id, index)
                                                                }}
                                                            >
                                                                닫기
                                                            </button>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}
                                </Swiper>
                            </div>
                        </div>
                    )
                )
            })}
        </Fragment>
    )
}

export default PopUpLayer

import React, { ReactNode, useContext, useState } from 'react'
import { Layout } from '../../layout'
import { UserContext } from '../../contexts'
import { useMessage } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'

const Secession = () => {
    const navigate = useNavigate()
    const userContext = useContext(UserContext)
    const [formData, setFormData] = useState({
        auth: userContext.user.auth,
        userId: userContext.user.id,
        password: '',
    })
    const [setMessage, messageContainer] = useMessage()
    const { doFetchJson } = useFetch()
    const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        setMessage('')
        if (!formData.password) {
            setMessage('비밀번호를 입력 해 주세요.')
            return
        }
        doFetchJson(
            '/user',
            'DELETE',
            JSON.stringify(formData),
            (data) => {
                userContext.removeUser()
                alert('탈퇴 처리 되었습니다.')
                // navigate('/')
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    return (
        <Layout active='my'>
            <div className='container'>
                <form className='form' onSubmit={submitHandler}>
                    <div className='wrapper'>
                        <label className='label'>이메일</label>
                        <span className='content'>{userContext.user.email}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>비밀번호</label>
                        <input
                            type='password'
                            name='password'
                            value={formData.password}
                            onChange={changeInputHandler}
                        />
                    </div>

                    <div className='warning-message'>
                        <h4>
                            가맹점 회원 탈퇴시 패스워드를 재입력하셔야 하며, 다음과 같은 법령에 준하여 정보가 저장 및
                            파기됨을 확인 후 탈퇴하시기 바랍니다.
                        </h4>
                        <ol>
                            <li>
                                <dl>
                                    <dt>탈퇴회원 정보 보유기간</dt>
                                    <dd>
                                        <dl>
                                            <dt>전자상거래 등에서의 소비자보호에 관한 법률 제6조제3항</dt>
                                            <dd>
                                                <ul>
                                                    <li>표시·광고에 관한 기록, 6개월</li>
                                                    <li>계약 또는 청약철회 등에 관한 기록, 5년</li>
                                                    <li>대금결제 및 재화 등의 공급에 관한 기록, 5년</li>
                                                    <li>소비자의 불만 또는 분쟁처리에 관한 기록, 3년</li>
                                                </ul>
                                            </dd>
                                        </dl>
                                        <dl>
                                            <dt>전자금융거래법 제22조제1항</dt>
                                            <dd>
                                                <ul>
                                                    <li>
                                                        전자금융 거래에 관한 기록, 건당 1만원 초과 : 5년 / 건당 1만원
                                                        이하 : 1년
                                                    </li>
                                                </ul>
                                            </dd>
                                        </dl>
                                        <dl>
                                            <dt>통신비밀보호법 제15조의2제2항</dt>
                                            <dd>
                                                <ul>
                                                    <li>서비스 이용 및 처리 등에 관한 기록, 3개월</li>
                                                </ul>
                                            </dd>
                                        </dl>
                                        <dl>
                                            <dt>국세기본법 제85조의3제2항</dt>
                                            <dd>
                                                <ul>
                                                    <li>제세공과금 납부 기록, 5년</li>
                                                </ul>
                                            </dd>
                                        </dl>
                                    </dd>
                                </dl>
                            </li>
                            <li>
                                <dl>
                                    <dt>파기 방법</dt>
                                    <dd>
                                        전자적 파일형태로 저장된 개인정보는 기술적 방법을 통해 파기하고 종이에 출력된
                                        개인정보는 분쇄하거나 소각을 통하여 파기.
                                    </dd>
                                </dl>
                            </li>
                        </ol>
                    </div>
                    <div className='wrapper check'>
                        <label>
                            <input type='checkbox' required /> 상기 내용을 확인 하였습니다.
                        </label>
                    </div>
                    {messageContainer}
                    <div className='buttons center'>
                        <button type='submit' className='error'>
                            탈퇴요청
                        </button>
                        <Link to={'/my'} className='primary'>
                            취소
                        </Link>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default Secession

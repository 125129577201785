import React, { useContext, useEffect, useState } from 'react'
import { Layout } from '../../layout'
import DaumPostcode, { Address } from 'react-daum-postcode'
import { MypageFormDataType } from '../../types/user'
import { useMessage } from '../../hooks'
import { ModalContext, UserContext } from '../../contexts'
import { Link } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import RegExp from '../../utils/RegExp'
const Mypage = () => {
    const userContext = useContext(UserContext)

    const modalContext = useContext(ModalContext)
    const [setMessage, messageContainer] = useMessage()
    const { doFetchJson } = useFetch()

    const auth = userContext.user.auth
    const [formData, setFormData] = useState<MypageFormDataType>({
        companyName: '',
        name: '',
        password: '',
        passwordConfirm: '',
        phone: '',
        zipCode: '',
        address: '',
        addressDetail: '',
        ceoName: '',
        businessNumber: '',
        licenseNumber: '',
        ceoEmail: '',
    })

    const completeAddressHandler = (data: Address) => {
        setFormData({ ...formData, zipCode: data.zonecode, address: data.address })
        modalContext.activeModalHandler(null)
    }
    useEffect(() => {
        doFetchJson(
            `/user/${userContext.user.auth}?userId=${userContext.user.id}`,
            'GET',
            null,
            (data) => {
                const user = data.item

                setFormData({
                    ...formData,
                    companyName: user.company_name,
                    name: user.name,
                    phone: user.phone,
                    zipCode: user.zipcode,
                    address: user.address,
                    addressDetail: user.address_detail,
                    ceoName: user.ceo_name,
                    businessNumber: user.business_number,
                    licenseNumber: user.license_number,
                    ceoEmail: user.ceo_email,
                })
            },
            (message) => {
                setMessage(message)
            }
        )
    }, [])

    const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value
        if (e.target.name == 'phone') {
            value = RegExp.replaceContact(e.target.value)
        } else if (e.target.name == 'businessNumber') {
            value = RegExp.replaceBusinessNumber(e.target.value)
        }
        setFormData({ ...formData, [e.target.name]: value })
    }
    const searchAddressHandler = () => {
        modalContext.activeModalHandler(<DaumPostcode onComplete={completeAddressHandler} />)
    }
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        setMessage('')

        if (formData.password || formData.passwordConfirm) {
            if (!RegExp.checkPassword(formData.password)) {
                setMessage('비밃번호 형식을 확인 해 주세요.')
                return
            }
            if (!RegExp.checkPassword(formData.passwordConfirm)) {
                setMessage('비밃번호 확인 형식을 확인 해 주세요.')
                return
            }
            if (formData.password !== formData.passwordConfirm) {
                setMessage('비밀번호와 비밀번호 확인의 값을 확인 해 주세요.')
                return
            }
        }

        if (userContext.user.auth == 4) {
            if (!formData.name) {
                setMessage('이름을 입력 해 주세요.')
                return
            }
            if (!formData.phone) {
                setMessage('핸드폰 번호를 입력 해 주세요.')
                return
            }
        } else if (userContext.user.auth == 3) {
            if (!formData.companyName) {
                setMessage('가맹점명을 입력 해 주세요.')
                return
            }
            if (!formData.businessNumber) {
                setMessage('사업자등록번호를 입력 해 주세요.')
                return
            }
            if (!formData.licenseNumber) {
                setMessage('업종면허번호를 입력 해 주세요.')
                return
            }
            if (!formData.ceoName) {
                setMessage('대표자를 입력 해 주세요.')
                return
            }
            if (!formData.name) {
                setMessage('이름을 입력 해 주세요.')
                return
            }
            if (!formData.phone) {
                setMessage('핸드폰 번호를 입력 해 주세요.')
                return
            }
        } else {
            if (!formData.companyName) {
                setMessage('기관명을 입력 해 주세요.')
                return
            }
            if (!formData.name) {
                setMessage('이름을 입력 해 주세요.')
                return
            }
            if (!formData.phone) {
                setMessage('핸드폰 번호를 입력 해 주세요.')
                return
            }
        }

        if (!formData.zipCode) {
            setMessage('우편번호를 입력 해 주세요.')
            return
        }
        if (!formData.address) {
            setMessage('주소를 입력 해 주세요.')
            return
        }
        if (!formData.addressDetail) {
            setMessage('상세주소를 입력 해 주세요.')
            return
        }

        doFetchJson(
            `/user/${userContext.user.auth}/${userContext.user.id}`,
            'PUT',
            JSON.stringify(formData),
            () => {
                alert('수정되었습니다.')
            },
            (message) => {
                setMessage(message)
            }
        )
    }

    return (
        <Layout active='my'>
            <div className='container'>
                <form onSubmit={submitHandler}>
                    <div className='wrapper'>
                        <label className='label'> 이메일</label>
                        <span>{userContext.user.email}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>비밀번호</label>
                        <input
                            type='password'
                            name='password'
                            value={formData.password}
                            onChange={changeInputHandler}
                        />
                        <p className='tool-tip'>8자리~15자리 영문 , 숫자 , 특수기호(!@#$%^*+=-) 입력 가능</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>비밀번호 확인</label>
                        <input
                            type='password'
                            name='passwordConfirm'
                            value={formData.passwordConfirm}
                            onChange={changeInputHandler}
                        />
                        <p className='tool-tip'>8자리~15자리 영문 , 숫자 , 특수기호(!@#$%^*+=-) 입력 가능</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>이름</label>
                        <input type='text' name='name' value={formData.name} onChange={changeInputHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>핸드폰번호</label>
                        <input type='text' name='phone' value={formData.phone} onChange={changeInputHandler} />
                    </div>

                    <div className='wrapper'>
                        <label className='label'>우편번호</label>
                        <input
                            type='text'
                            name='zipCode'
                            value={formData.zipCode}
                            onChange={changeInputHandler}
                            onClick={searchAddressHandler}
                            readOnly={true}
                        />
                        <button type='button' onClick={searchAddressHandler} className='button normal'>
                            주소검색
                        </button>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>주소</label>
                        <input
                            type='text'
                            name='address'
                            value={formData.address}
                            onChange={changeInputHandler}
                            onClick={searchAddressHandler}
                            readOnly={true}
                        />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>상세주소</label>
                        <input
                            type='text'
                            name='addressDetail'
                            value={formData.addressDetail}
                            onChange={changeInputHandler}
                        />
                    </div>
                    {/* <MypageAuth changeInputHandler={changeInputHandler} formData={formData} /> */}
                    {auth == 3 ? (
                        <>
                            <div className='wrapper'>
                                <label className='label'>가맹점명</label>
                                <input
                                    type='text'
                                    name='companyName'
                                    value={formData.companyName}
                                    onChange={changeInputHandler}
                                />
                            </div>
                            <div className='wrapper'>
                                <label className='label'>대표자</label>
                                <input
                                    type='text'
                                    name='ceoName'
                                    value={formData.ceoName}
                                    onChange={changeInputHandler}
                                />
                            </div>
                            <div className='wrapper'>
                                <label className='label'>사업자등록번호</label>
                                <input
                                    type='text'
                                    name='businessNumber'
                                    value={formData.businessNumber}
                                    onChange={changeInputHandler}
                                />
                            </div>
                            <div className='wrapper'>
                                <label className='label'>업종면허번호</label>
                                <input
                                    type='text'
                                    name='licenseNumber'
                                    value={formData.licenseNumber}
                                    onChange={changeInputHandler}
                                />
                            </div>
                        </>
                    ) : auth == 4 ? (
                        <></>
                    ) : (
                        <div className='wrapper'>
                            <label className='label'>기관명(업체명)</label>
                            <input
                                type='text'
                                name='companyName'
                                value={formData.companyName}
                                onChange={changeInputHandler}
                            />
                        </div>
                    )}

                    {messageContainer}
                    <div className='buttons center'>
                        <button type='submit' className='info'>
                            저장
                        </button>
                        <Link to='/my/secession' className='error'>
                            탈퇴
                        </Link>
                        <Link to='/' className='primary'>
                            취소
                        </Link>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default Mypage

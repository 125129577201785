import { useEffect, useState } from 'react'
import DateFormat from '../utils/DateFormat'
type Option = {
    label: string
    value: string

    unit?: string
    elements?: Option[]
}
const AsCheckForm = () => {
    const today = new Date()

    const [formData, setFormData] = useState({
        type: '0',
        date: DateFormat.Date(today),
        value_0: '0',
        value_1: '0',
    })

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        setResultMessage('')
        //완공일
        const date = new Date(formData.date)
        //연면적
        const value0 = parseInt(formData.value_0)
        //공동주택
        const value1 = parseInt(formData.value_1)
        if (value0 >= 3 || value1 >= 3) {
            //건축물 연면적 3만 m^2 이상이거나 공동주택 3,000 세대 이상일때
            date.setFullYear(date.getFullYear() + 5)
            if (date <= today) {
                setResultMessage(`관리 대상임`)
                return
            } else {
                setResultMessage(`${date.getFullYear()}년7 월18일 부터 관리 대상임`)
                return
            }
        }
        if (value0 >= 2 || value1 >= 2) {
            //건축물 연면적 3천 m^2 이상이거나 공동주택 500 세대 이상일때
            date.setFullYear(date.getFullYear() + 6)
            if (date <= today) {
                //건축물 완공일 + 6년 >= 현재일 이면
                setResultMessage(`관리 대상임`)
                return
            } else {
                setResultMessage(`${date.getFullYear()}년7 월18일 부터 관리 대상임`)
                return
            }
        }
        if (value0 >= 1 || value1 >= 1) {
            //건축물 연면적 1천 m^2 이상이거나 공동주택 300 세대 이상일때
            date.setFullYear(date.getFullYear() + 7)
            if (date <= today) {
                setResultMessage(`관리 대상임`)
                return
            } else {
                setResultMessage(`${date.getFullYear()}년7 월18일 부터 관리 대상임`)
                return
            }
        }
        setResultMessage('해당사항 없음')
    }, [formData])

    const [resultMessage, setResultMessage] = useState<string>('')
    return (
        <div className='as-container'>
            <div className='header'>
                <span>정보통신 유지관리•성능점검</span> <span>대상 건축물여부 바로 확인하기</span>
            </div>
            <div className='body'>
                <div className='result-container'>
                    <span>[통신 유지관리/성능점검 대상여부]</span>
                    <span className='result'>{resultMessage}</span>
                </div>
                <div className='type-container'>
                    <label>
                        <input
                            type='radio'
                            name='type'
                            value='0'
                            checked={formData.type === '0'}
                            onChange={changeHandler}
                        />
                        <span className='label'>건축물 연면적</span>
                    </label>
                    <label>
                        <input
                            type='radio'
                            name='type'
                            value='1'
                            checked={formData.type === '1'}
                            onChange={changeHandler}
                        />
                        <span className='label'>공동주택 세대수</span>
                    </label>
                </div>
                <div className='option-container'>
                    <label>
                        <span className='label'>건축물 완공일</span>
                        <input type='date' name='date' onChange={changeHandler} value={formData.date} />
                    </label>
                    {formData.type === '0' && (
                        <label>
                            <span className='label'>건축물 연면적</span>
                            <select name='value_0' value={formData.value_0} onChange={changeHandler}>
                                <option value={0}>0~1,000</option>
                                <option value={1}>1,000~3,000</option>
                                <option value={2}>3,000~30,000</option>
                                <option value={3}>30,000~</option>
                            </select>
                            <span className='label'>㎡ 미만</span>
                        </label>
                    )}
                    {formData.type === '1' && (
                        <label>
                            <span className='label'>공동주택 세대수</span>
                            <select name='value_1' value={formData.value_1} onChange={changeHandler}>
                                <option value={0}>0~300</option>
                                <option value={1}>300~500</option>
                                <option value={2}>500~3,000</option>
                                <option value={3}>3,000~</option>
                            </select>
                            <span className='label'> 미만</span>
                        </label>
                    )}
                </div>
            </div>
            <div className='footer'>
                「정보통신공사업법」 , 법률 제 19546호, 2023.7.18. 일부개정, 2024.7.19 시행 예정.(시행 後 변동가능)
            </div>
        </div>
    )
}

export default AsCheckForm

import React, { useContext, useState } from 'react'
import { Layout } from '../../layout'
import { GlobalContext } from '../../contexts'
import RegExp from '../../utils/RegExp'
import useFetch from '../../hooks/useFetch'

const Config = () => {
    const globalContext = useContext(GlobalContext)
    const { config, serviceCategory } = globalContext.globalValue
    const [formData, setFormData] = useState({
        ...config,
        travel_cost: RegExp.replaceCost(config.travel_cost.toString()),
        travel_cost_holiday: RegExp.replaceCost(config.travel_cost_holiday.toString()),
    })
    const { doFetchJson } = useFetch()
    const changeHandler = (e: React.ChangeEvent<any>) => {
        if (e.target.name == 'travel_cost' || e.target.name == 'travel_cost_holiday') {
            setFormData({ ...formData, [e.target.name]: RegExp.replaceCost(e.target.value) })
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }
    const updateConfigHandler = () => {
        doFetchJson(
            '/config',
            'PUT',
            JSON.stringify(formData),
            () => {
                globalContext.setGlobalValue({ ...globalContext.globalValue, config: { ...formData } })
                alert('수정되었습니다')
            },
            (message) => {
                console.log(message)
            }
        )
    }
    return (
        <Layout active='config'>
            <div className='container'>
                <form>
                    <div className='wrapper'>
                        <label className='label'>기본출장비</label>
                        <input type='text' name='travel_cost' value={formData.travel_cost} onChange={changeHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>주말 및 공휴일 기본출장비</label>
                        <input
                            type='text'
                            name='travel_cost_holiday'
                            value={formData.travel_cost_holiday}
                            onChange={changeHandler}
                        />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>출장비무료</label>
                        <div className='switch input'>
                            <label>
                                <input
                                    type='radio'
                                    name='apply_travel_cost'
                                    defaultChecked={formData.apply_travel_cost == 0}
                                    value={0}
                                    onChange={changeHandler}
                                />
                                무료
                            </label>
                            <label>
                                <input
                                    type='radio'
                                    name='apply_travel_cost'
                                    defaultChecked={formData.apply_travel_cost == 1}
                                    value={1}
                                    onChange={changeHandler}
                                />
                                유료
                            </label>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>대표이사 이메일</label>
                        <input type='email' name='ceo_email' value={formData.ceo_email} onChange={changeHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>CS이메일</label>
                        <input type='email' name='email' value={formData.email} onChange={changeHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>CS연락처</label>
                        <input type='text' name='contact' value={formData.contact} onChange={changeHandler} />
                    </div>
                    <div className='buttons center'>
                        <button type='button' className='primary' onClick={updateConfigHandler}>
                            저장
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default Config

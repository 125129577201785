import React, { useContext, useState } from 'react'
import ModalContext from '../../contexts/ModalContext'
import authList from '../../json/auth.json'
import useFetch from '../../hooks/useFetch'
import RegExp from '../../utils/RegExp'
const FindPasswordModal = () => {
    const modalContext = useContext(ModalContext)

    const [userData, setUserData] = useState<any>(null)
    const { doFetchJson } = useFetch()
    const submitUpdateFormHandle = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setMessage('')
        if (!updateFormData.password) {
            setMessage('비밀번호를 입력 해 주세요.')
            return
        }
        if (!updateFormData.passwordConfirm) {
            setMessage('비밀번호 확인을 입력 해 주세요.')
            return
        }
        if (updateFormData.password !== updateFormData.passwordConfirm) {
            setMessage('비밀번호와 비밀번호 확인의 값이 다릅니다.')
            return
        }
        doFetchJson(
            '/auth/updatePassword',
            'PUT',
            JSON.stringify({ ...updateFormData, userId: userData.id, auth: userData.auth }),
            (data) => {
                alert('변경되었습니다.')
                modalContext.activeModalHandler(null)
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const submitFormHandle = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!formData.email) {
            setMessage('이메일을 입력 해 주세요.')
            return
        }
        if (!formData.phone) {
            setMessage('폰 번호를 입력 해 주세요.')
            return
        }
        setMessage('')
        doFetchJson(
            '/auth/findPassword',
            'POST',
            JSON.stringify(formData),
            (data) => {
                setUserData(data)
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        auth: parseInt(localStorage.getItem('auth') ?? '1'),
    })
    const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value
        if (e.target.name === 'phone') {
            value = RegExp.replaceContact(value)
        }
        setFormData({ ...formData, [e.target.name]: value })
    }
    const [message, setMessage] = useState('')

    const closeModalHandle = () => {
        modalContext.activeModalHandler(null)
    }

    const findForm = (
        <form className='form' onSubmit={submitFormHandle}>
            <div className='wrapper auth-list'>
                {authList.map((auth, index) => {
                    return (
                        <label key={index}>
                            <input
                                type='radio'
                                name='auth'
                                value={auth.auth}
                                defaultChecked={formData.auth === auth.auth}
                                onChange={changeInputHandler}
                            />
                            {auth.name}
                        </label>
                    )
                })}
            </div>
            <div className='wrapper'>
                <label className='label'>이메일</label>
                <input type='email' name='email' value={formData.email} onChange={changeInputHandler} />
            </div>
            <div className='wrapper'>
                <label className='label'>핸드폰 번호</label>
                <input type='text' name='phone' value={formData.phone} onChange={changeInputHandler} />
            </div>
            <div className='message-container'>
                <span>{message}</span>
            </div>
            <div className='buttons right'>
                <button type='submit' className='primary'>
                    찾기
                </button>
                <button type='button' className='error' onClick={closeModalHandle}>
                    닫기
                </button>
            </div>
        </form>
    )
    const [updateFormData, setUpdateFormData] = useState({
        password: '',
        passwordConfirm: '',
    })
    const updateFormInputHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateFormData({ ...updateFormData, [e.target.name]: e.target.value })
    }
    const updateForm = (
        <form className='form' onSubmit={submitUpdateFormHandle}>
            <div className='wrapper'>
                <label className='label'>비밀번호</label>
                <input
                    type='password'
                    name='password'
                    value={updateFormData.password}
                    onChange={updateFormInputHandle}
                />
                <p className='tool-tip'>8자리~15자리 영문 , 숫자 , 특수기호(!@#$%^*+=-) 입력 가능</p>
            </div>
            <div className='wrapper'>
                <label className='label'>비밀번호 확인</label>
                <input
                    type='password'
                    name='passwordConfirm'
                    value={updateFormData.passwordConfirm}
                    onChange={updateFormInputHandle}
                />
                <p className='tool-tip'>8자리~15자리 영문 , 숫자 , 특수기호(!@#$%^*+=-) 입력 가능</p>
            </div>
            <div className='message-container'>
                <span>{message}</span>
            </div>
            <div className='buttons right'>
                <button type='submit' className='primary'>
                    변경
                </button>
                <button type='button' className='error' onClick={closeModalHandle}>
                    닫기
                </button>
            </div>
        </form>
    )
    return <div className='container'>{userData === null ? findForm : updateForm}</div>
}

export default FindPasswordModal

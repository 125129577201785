import React, { FC, useContext, useState } from 'react'
import { GlobalContext, UserContext } from '../contexts'
import { Layout } from '../layout'
import useFileUploader from '../hooks/useFileUploader'
import { useMessage } from '../hooks'
import useFetch from '../hooks/useFetch'
import RegExp from '../utils/RegExp'

type Props = {
    item: any
    cancelHandler: () => void
}
const OfflinePayment: FC<Props> = ({ item, cancelHandler }) => {
    const globalContext = useContext(GlobalContext)
    const categories = globalContext.globalValue.serviceCategory
    const userContext = useContext(UserContext)
    const user = userContext.user
    const [setMessage, messageContainer] = useMessage()
    const { doFetchForm } = useFetch()
    const submitFormHandler = (e: React.FormEvent) => {
        e.preventDefault()
        setMessage('')
        // if (!formData.description) {
        // setMessage('제출 서류를 입력 해 주세요.')
        // return
        // }
        // if (!files.length) {
        // setMessage('양식파일을 업로드 해 주세요.')
        // return
        // }

        const fetchBody = new FormData()
        fetchBody.append('description', formData.description)
        fetchBody.append('serviceId', formData.serviceId)
        fetchBody.append('userId', formData.userId.toString())
        fetchBody.append('amount', formData.amount)
        files.forEach((file) => {
            fetchBody.append('documentFiles', file)
        })
        doFetchForm(
            '/pay/off',
            'POST',
            fetchBody,
            (data) => {
                alert('등록되었습니다.')
                cancelHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const [formData, setFormdata] = useState({
        description: '',
        serviceId: item.id,
        userId: user.id,
        amount: item.cost,
    })
    const [files, fileContainer] = useFileUploader({ maxFileCount: 3 })
    const changeInputHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }
    return (
        <div className='container'>
            <form onSubmit={submitFormHandler}>
                <div className='wrapper'>
                    <label className='label'>분류</label>
                    <span>
                        {categories.find((category) => category.id == item.category_depth_1)?.category_name}
                        &gt;
                        {categories.find((category) => category.id == item.category_depth_2)?.category_name}
                        &gt;
                        {categories.find((category) => category.id == item.category_depth_3)?.category_name}
                    </span>
                </div>
                <div className='wrapper'>
                    <label className='label'>기업명</label>
                    <span>{item.company_name ?? ''}</span>
                </div>
                <div className='wrapper'>
                    <label className='label'>대리/가맹점</label>
                    <span>{item.manager?.company_name}</span>
                    {/* <span>{managerList.find((manager) => item.manager_id == manager.id)?.company_name}</span> */}
                </div>
                <div className='wrapper'>
                    <label className='label'>접수제목</label>
                    <span>{item.title}</span>
                </div>
                {/* <div className='wrapper'>
                        <label className='label'>요청일자</label>
                        <span>{item.service_date}</span>
                    </div> */}
                <div className='wrapper'>
                    <label className='label'>출장비</label>
                    {/* <span>{item.travel_cost}원</span> */}
                    <span>
                        {globalContext.globalValue.config['apply_travel_cost'] ? (
                            <span>{RegExp.replaceCost(item.travel_cost.toString())}원</span>
                        ) : (
                            <span>
                                <span className='strike'>{RegExp.replaceCost(item.travel_cost.toString())}원</span>
                                (출장비무료)
                            </span>
                        )}
                    </span>
                </div>
                <div className='wrapper'>
                    <label className='label'>견적단가</label>
                    <span>{item.cost}원</span>
                </div>

                <div className='wrapper'>
                    <p className='guide'>대금 청구시 필요한 사항 기재요청</p>
                    <label className='label ta'>제출 서류</label>
                    <textarea
                        value={formData.description}
                        name='description'
                        placeholder='해당사항 없음&#13;&#10;1.대금청구서&#13;&#10;2.세금계산서&#13;&#10;3.통장사본&#13;&#10;4.하자보수 보증금 지급각서'
                        onChange={changeInputHandler}
                    ></textarea>
                </div>
                <div className='wrapper'>
                    <p className='guide'>
                        사업자등록증 및 필요 양식 파일등을 업로드 하시면 결제 완료 후 작성하여 보내도록 하겠습니다.
                    </p>
                    <label className='label'>양식파일</label>
                    {fileContainer}
                </div>
                {messageContainer}
                <div className='buttons center'>
                    <button type='submit' className='primary'>
                        결제 완료
                    </button>
                    <button type='button' className='error' onClick={cancelHandler}>
                        취소
                    </button>
                </div>
            </form>
        </div>
    )
}

export default OfflinePayment

import React, { useContext, useEffect, useState } from 'react'
import { BoardPreview } from '../../components'
import { Layout } from '../../layout'
import { Link } from 'react-router-dom'
import UserContext from '../../contexts/UserContext'
import ModalContext from '../../contexts/ModalContext'
import FindPasswordModal from './FindPasswordModal'
import FindIdModal from './FindIdModal'

import authList from '../../json/auth.json'
import { useMessage } from '../../hooks'
import FiindEmailModal from './FindEmailModal'
import { GlobalContext } from '../../contexts'
import RegExp from '../../utils/RegExp'
import useFetch from '../../hooks/useFetch'

const SignIn = () => {
    const globalContext = useContext(GlobalContext)
    const { config } = globalContext.globalValue
    const userContext = useContext(UserContext)
    const modalContext = useContext(ModalContext)
    const { doFetchJson } = useFetch()
    const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const [formData, setFormData] = useState({
        auth: parseInt(localStorage.getItem('auth') ?? '1'),
        email: '',
        password: '',
    })

    const [setMessage, messageContainer] = useMessage()
    const submitFormHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setMessage('')
        if (!formData.email) {
            setMessage('이메일을 입력 해 주세요.')
            return
        }
        if (!RegExp.checkEmail(formData.email)) {
            setMessage('이메일 형식을 확인 해 주세요.')
            return
        }
        if (!formData.password) {
            setMessage('비밀번호를 입력 해 주세요.')
            return
        }
        doFetchJson(
            '/auth/signIn',
            'POST',
            JSON.stringify(formData),
            (data) => {
                userContext.updateUser(data.user)
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    const finEmailModalHandler = () => {
        modalContext.activeModalHandler(<FiindEmailModal />, '이메일 찾기')
    }
    const findPwModalHandler = () => {
        modalContext.activeModalHandler(<FindPasswordModal />, '비밀번호 찾기')
    }
    useEffect(() => {
        localStorage.setItem('auth', formData.auth.toString())
    }, [formData.auth])
    return (
        <Layout title='로그인'>
            <div className='container row'>
                <div className='signin'>
                    <form onSubmit={submitFormHandler}>
                        <div className='auth-list'>
                            {authList.map((auth, index) => {
                                return (
                                    <label key={index}>
                                        <input
                                            type='radio'
                                            name='auth'
                                            value={auth.auth}
                                            defaultChecked={formData.auth == auth.auth}
                                            onChange={changeInputHandler}
                                        />
                                        {auth.name}
                                    </label>
                                )
                            })}
                        </div>
                        <div className='wrapper'>
                            <label className='label'>이메일</label>
                            <input type='text' name='email' value={formData.email} onChange={changeInputHandler} />
                        </div>
                        <div className='wrapper'>
                            <label className='label'>패스워드</label>
                            <input
                                type='password'
                                name='password'
                                value={formData.password}
                                onChange={changeInputHandler}
                                autoComplete='off'
                            />
                        </div>
                        {messageContainer}
                        <div className='buttons center'>
                            <button type='submit' className='primary'>
                                로그인
                            </button>
                        </div>
                        <div className='links'>
                            <span style={{ cursor: 'pointer' }} onClick={finEmailModalHandler}>
                                이메일 찾기
                            </span>
                            |
                            <span style={{ cursor: 'pointer' }} onClick={findPwModalHandler}>
                                비밀번호 찾기
                            </span>
                            |<Link to={'/signUp'}>회원가입</Link>
                        </div>
                    </form>
                </div>
                <BoardPreview type={[1, 2]} />
            </div>
        </Layout>
    )
}

export default SignIn

import React, { FC, ReactElement, ReactNode, useEffect, useState } from 'react'
import ModalContext from '../contexts/ModalContext'
import '../stylesheets/modal.css'

import closeIcon from '../icons/icon_close.svg'
const ModalProvider: FC<React.HTMLProps<any>> = (props) => {
    const [showTitle, setShowTitle] = useState('')
    const [activeModal, setActiveModal] = useState<ReactNode>(null)
    const [modal, setModal] = useState<ReactElement>()
    const modalHandle = (node: ReactNode, title: string | undefined) => {
        setActiveModal(node)
        setShowTitle(title ?? '')
    }
    const closeModalHandler = (e: React.MouseEvent) => {
        setActiveModal(null)
    }
    useEffect(() => {
        if (activeModal === null) {
            document.body.classList.remove('active-modal')
            setModal(<></>)
        } else {
            document.body.classList.add('active-modal')
            setModal(
                <div id='modal' onClick={closeModalHandler}>
                    <div className='modal-container' onClick={(e: React.MouseEvent) => e.stopPropagation()}>
                        <div className='modal-header'>
                            <h3>{showTitle}</h3>
                            <img src={closeIcon} alt='close' onClick={closeModalHandler} />
                        </div>
                        <div className='modal-content'>{activeModal}</div>
                    </div>
                </div>
            )
        }
    }, [activeModal])

    return (
        <ModalContext.Provider value={{ activeModalHandler: modalHandle }}>
            {props.children}
            {modal}
        </ModalContext.Provider>
    )
}

export default ModalProvider

import { Layout } from '../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import boardList from '../../json/board.json'
import tagList from '../../json/tag.json'
import { useContext, useEffect, useState } from 'react'
import useFileUploader from '../../hooks/useFileUploader'
import { UserContext } from '../../contexts'
import { useMessage } from '../../hooks'
import useFetch from '../../hooks/useFetch'
const BoadrWrite = () => {
    const userContext = useContext(UserContext)
    const { boardType } = useParams()
    const navigate = useNavigate()
    const boardTitle = boardList.find((board) => board.id.toString() == boardType)?.name
    const [formData, setFormdata] = useState({
        tag: 1,
        title: '',
        content: '',
        toUserId: 0,
    })
    const [files, fileContainer] = useFileUploader({
        maxFileCount: 3,
    })
    const { doFetchForm, doFetchJson } = useFetch()
    const changeInputHandler = (e: React.ChangeEvent<any>) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }
    const [setMessage, messageContainer] = useMessage()
    const [userList, setUserList] = useState<any[]>([])
    useEffect(() => {
        doFetchJson('/user/managerList', 'GET', null, (data) => {
            setUserList(data.manager)
        })
    }, [])
    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault()
        const fetchBody = new FormData()
        if (!boardType) {
            setMessage('잘못된 접근입니다')
            return
        }
        if (!formData.title) {
            setMessage('제목을 입력 해 주세요.')
            return
        }
        if (!formData.content) {
            setMessage('내용을 입력 해 주세요.')
            return
        }
        fetchBody.append('userId', userContext.user.id.toString())
        fetchBody.append('tag', formData.tag.toString())
        fetchBody.append('title', formData.title)
        fetchBody.append('content', formData.content)
        fetchBody.append('toUserId', formData.toUserId.toString())
        files.forEach((file) => {
            fetchBody.append('uploadFiles', file)
        })
        doFetchForm(
            `/board/${boardType}`,
            'POST',
            fetchBody,
            () => {
                alert('등록되었습니다.')
                navigate(-1)
            },
            (message) => {
                setMessage(message)
            }
        )
    }
    let active = 'board'
    if (boardType == '1') {
        active = 'board1'
    } else if (boardType == '2') {
        active = 'board2'
    }
    return (
        <Layout active={active}>
            <form onSubmit={submitHandler}>
                <div className='container'>
                    <div className='wrapper'>
                        <label className='label'>구분</label>
                        <select value={formData.tag} name='tag' onChange={changeInputHandler}>
                            {tagList.map((tag, index) => {
                                return (
                                    <option key={index} value={tag.id}>
                                        {tag.name}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>열람회원</label>
                        <select onChange={changeInputHandler} name='toUserId' value={formData.toUserId}>
                            <option value={0}>전체공개</option>
                            {userList.map((user, index) => {
                                return (
                                    <option value={user.id} key={index}>
                                        {user.name} / {user.email}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>제목</label>
                        <input
                            type='text'
                            name='title'
                            value={formData.title}
                            onChange={changeInputHandler}
                            maxLength={50}
                        />
                    </div>
                    <div className='wrapper'>
                        <label className='label ta'>내용</label>
                        <textarea name='content' value={formData.content} onChange={changeInputHandler} />
                    </div>
                    <div className='wrapper'>
                        <label className='label'>자료</label>
                        {fileContainer}
                    </div>

                    {messageContainer}
                    <div className='buttons right'>
                        <button type='submit' className='primary'>
                            등록
                        </button>
                        <button
                            type='button'
                            className='error'
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            취소
                        </button>
                    </div>
                </div>
            </form>
        </Layout>
    )
}

export default BoadrWrite

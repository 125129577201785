import { Layout } from '../layout'
import { BoardPreview } from '../components'
import { useContext } from 'react'
import { UserContext } from '../contexts'

const Main = () => {
    const { user } = useContext(UserContext)
    return (
        <Layout active='main'>
            <div className='container'>
                {user.auth == 4 ? (
                    <>
                        <BoardPreview type={[1]} />
                        <BoardPreview type={[2]} />
                    </>
                ) : (
                    <BoardPreview type={[1, 2]} />
                )}
            </div>
        </Layout>
    )
}

export default Main

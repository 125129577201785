import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { Layout } from '../../layout'
import statusList from '../../json/status.json'
import atList from '../../json/at.json'
import { GlobalContext, UserContext } from '../../contexts'
import DateFormat from '../../utils/DateFormat'
import usePagination from '../../hooks/usePagination'
import { useNavigate } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'

type Props = {
    filterOptions: any
    clickListItemHandler: (index: number) => void
    onChangeFilterHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
    items: any[]
    paginationContainer: ReactNode
}
// const ServiceList: FC<Props> = ({ filterOptions, clickListItemHandler, onChangeFilterHandler, items, paginationContainer }) => {
const ServiceList = () => {
    const navigate = useNavigate()
    const globalContext = useContext(GlobalContext)
    const categories: any[] = globalContext.globalValue.serviceCategory
    const userContext = useContext(UserContext)
    const user = userContext.user
    const [totalCount, setTotalCount] = useState<number>(0)

    const [items, setItems] = useState<any[]>([])
    const { doFetchJson } = useFetch()
    const [page, paginationContainer, initPage] = usePagination({
        blockSize: 10,
        totalCount: totalCount,
        size: 10,
    })
    const [filterOptions, setFilterOptions] = useState<any>({
        category1: -1,
        category2: -1,
        category3: -1,
        title: '',
        status: -1,
        companyName: '',
        size: 10,
        order: 'id',
        sort: 'desc',
    })
    const onChangeFilterHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        initPage()
        setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value })
    }
    const setListItems = () => {
        const queryString = Object.entries({ ...filterOptions, page: page, userId: user.id, auth: user.auth })
            .map((item) => {
                return item.join('=')
            })
            .join('&')
        doFetchJson(`/service?${queryString}`, 'GET', null, (data) => {
            setItems(data.items)
            setTotalCount(data.totalCount)
        })
    }
    const clickListItemHandler = (id: number) => {
        navigate(`/service/view/${id}`)
    }
    useEffect(() => {
        setListItems()
    }, [filterOptions, page])

    const excelDownloadHandler = () => {
        const queryString = Object.entries({
            categoryDepth1: filterOptions.category1,
            categoryDepth2: filterOptions.category2,
            categoryDepth3: filterOptions.category3,
            status: filterOptions.status,
            userId: user.id,
            authLevel: user.level,
        })
            .map((item) => {
                return item.join('=')
            })
            .join('&')

        fetch(`${process.env.REACT_APP_URL}/service/excel?${queryString}`)
            .then((response) => {
                return response.blob()
            })
            .then((blob: Blob) => {
                const tempLink = document.createElement('a')
                tempLink.href = window.URL.createObjectURL(blob)
                tempLink.download = '서비스목록_' + DateFormat.DateTimeString(new Date()) + '.xlsx'
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            })
    }
    return (
        <Layout active='service2'>
            <div className='container list'>
                <div className='filter'>
                    <div className='wrapper'>
                        <label className='label'>대분류</label>
                        <select name='category1' onChange={onChangeFilterHandler}>
                            <option value={-1}>전체</option>
                            {categories
                                .filter((item) => {
                                    return item.type == 1 && item.depth == 1
                                })
                                .map((item, index) => {
                                    return (
                                        <option value={item.id} key={index}>
                                            {item.category_name}
                                        </option>
                                    )
                                })}
                        </select>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>중분류</label>
                        <select name='category2' onChange={onChangeFilterHandler}>
                            <option value={-1}>전체</option>
                            {categories
                                .filter((item) => {
                                    return item.type == 2 && item.depth == 2 && item.parent_id == 0
                                })
                                .map((item, index) => {
                                    return (
                                        <option value={item.id} key={index}>
                                            {item.category_name}
                                        </option>
                                    )
                                })}
                        </select>
                    </div>
                    {filterOptions.category2 != -1 && (
                        <div className='wrapper'>
                            <label className='label'>소분류</label>
                            <select name='category3' onChange={onChangeFilterHandler}>
                                <option value={-1}>전체</option>
                                {categories
                                    .filter((item) => {
                                        return (
                                            item.type == 2 &&
                                            item.depth == 3 &&
                                            item.parent_id == filterOptions.category2
                                        )
                                    })
                                    .map((item, index) => {
                                        return (
                                            <option value={item.id} key={index}>
                                                {item.category_name}
                                            </option>
                                        )
                                    })}
                            </select>
                        </div>
                    )}
                    {user.auth === 4 && (
                        <>
                            <div className='wrapper'>
                                <label className='label'>고객아이디/이름</label>
                                <input type='text' name='userId' onChange={onChangeFilterHandler} />
                            </div>
                            <div className='wrapper'>
                                <label className='label'>업체명</label>
                                <input type='text' name='companyName' onChange={onChangeFilterHandler} />
                            </div>
                        </>
                    )}
                    <div className='wrapper'>
                        <label className='label'>진행상황</label>
                        <select name='status' onChange={onChangeFilterHandler} value={filterOptions.status}>
                            <option value={-1}>전체</option>
                            {statusList.map((item, index) => {
                                return (
                                    <option value={item.status} key={index}>
                                        {item.name}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className='service list'>
                    <div className='header'>
                        <h5>서비스 현황</h5>
                    </div>
                    <div className='content'>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '4rem' }}>번호</th>
                                    <th style={{ width: '8rem' }}>진행상황</th>
                                    <th style={{ width: '10rem' }}>작업일자</th>
                                    <th style={{ width: '10rem' }}>완료일자</th>
                                    <th style={{ width: '20rem' }}>분류</th>
                                    <th style={{ width: '15rem' }}>제목</th>
                                    <th style={{ width: '10rem' }}>기업명</th>
                                    <th style={{ width: '5rem' }}>부서명</th>
                                    <th style={{ width: '5rem' }}>담당자</th>

                                    <th style={{ width: '8rem' }}>출장비</th>
                                    <th style={{ width: '8rem' }}>견적단가</th>
                                    <th style={{ width: '8rem' }}>관련문서</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {
                                    const numbering = totalCount - (1 - 1) * 5 - index
                                    return (
                                        <tr
                                            key={index}
                                            onClick={() => {
                                                clickListItemHandler(item.id)
                                            }}
                                        >
                                            <td>{numbering}</td>
                                            <td>{statusList.find((status) => status.status == item.status)?.name}</td>
                                            <td>
                                                {item.service_date ? DateFormat.Date(new Date(item.service_date)) : '-'}{' '}
                                                {atList.find((at) => at.at == item.service_date_at)?.name}
                                            </td>
                                            <td>
                                                {item.complete_at
                                                    ? DateFormat.DateTime(new Date(item.complete_at))
                                                    : '-'}
                                            </td>
                                            <td>
                                                {
                                                    categories.find((category) => category.id === item.category_depth_1)
                                                        ?.category_name
                                                }
                                                &gt;
                                                {
                                                    categories.find((category) => category.id === item.category_depth_2)
                                                        ?.category_name
                                                }
                                                &gt;
                                                {
                                                    categories.find((category) => category.id === item.category_depth_3)
                                                        ?.category_name
                                                }
                                            </td>
                                            <td>{item.title}</td>
                                            <td>{item.company_name}</td>
                                            <td>{item.team_name}</td>
                                            <td>{item.user_name}</td>

                                            <td>{item.travel_cost}원</td>
                                            <td>{item.cost}원</td>
                                            <td>
                                                <p>{item.upload_file_index && '첨부파일'}</p>
                                                <p>{item.estimate_file_index && '견적서'}</p>
                                                <p>{item.report_file_index && '완료보고서'}</p>
                                                <p>{item.complete_file_index && '완료파일'}</p>
                                                <p>{item.document_file_index && '대금양식'}</p>
                                            </td>
                                        </tr>
                                    )
                                })}
                                {totalCount == 0 && (
                                    <tr className='empty'>
                                        <td colSpan={12}>조회된 데이터가 없습니다.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {items.length != 0 && (
                        <div className='excel-button'>
                            <button type='button' onClick={excelDownloadHandler}>
                                엑셀다운
                            </button>
                        </div>
                    )}
                    {paginationContainer}
                </div>
            </div>
        </Layout>
    )
}

export default ServiceList

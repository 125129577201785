import { useState } from 'react'
import SignUpForm from './SignUpForm'
import ConfirmSignUp from './ConfirmSignUp'
import { SignUpFormType } from '../../types/auth'

const SignUp = () => {
    const [step, setStep] = useState(0)
    const [formData, setFormData] = useState<SignUpFormType>({
        type: 0,
        auth: parseInt(localStorage.getItem('auth') ?? '1'),
        // id: '',
        password: '',
        passwordConfirm: '',
        companyName: '',
        name: '',
        email: '',
        emailValid: '',
        phone: '',
        zipcode: '',
        address: '',
        addressDetail: '',
        ceoName: '',
        businessNumber: '',
        licenseNumber: '',
        ceoEmail: '',
    })
    const [idValid, setIdValid] = useState(false)
    //이메일 인증 통과 되었는지 boolean
    const [emailValid, setEmailValid] = useState(false)
    if (step === 0) {
        return <SignUpForm setStep={setStep} formData={formData} setFormData={setFormData} idValid={idValid} setIdValid={setIdValid} emailValid={emailValid} setEmailValid={setEmailValid} />
    } else {
        return <ConfirmSignUp setStep={setStep} formData={formData} />
    }
}

export default SignUp

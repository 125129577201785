import { FC, useContext } from 'react'
import { Layout } from '../../layout'
import { GlobalContext, ModalContext } from '../../contexts'
import { useNavigate } from 'react-router-dom'
import useFileUploader from '../../hooks/useFileUploader'
import DateFormat from '../../utils/DateFormat'
import atList from '../../json/at.json'
import statusList from '../../json/status.json'
import OnlinePayment from '../../components/OnlinePayment'
import OfflinePayment from '../../components/OfflinePayment'
import payList from '../../json/pay.json'
import { useMessage } from '../../hooks'
import useFetch from '../../hooks/useFetch'
import RegExp from '../../utils/RegExp'
type Props = {
    item: any
    reviewHandler: () => void
    setItemHandler: () => void
}
const ServiceUser: FC<Props> = ({ item, reviewHandler, setItemHandler }) => {
    const navigate = useNavigate()
    const globalContext = useContext(GlobalContext)
    const modalContext = useContext(ModalContext)

    const categories: any[] = globalContext.globalValue.serviceCategory
    const { doFetchJson } = useFetch()
    const [upFiles, upFilesContainer, upFilesResultContainer] = useFileUploader({
        maxFileCount: 5,

        defaultFiles: item.files.uploadFiles,
    })
    const [estimateFiles, estimateFileContainer, estimateFileResultContainer] = useFileUploader({
        maxFileCount: 5,

        defaultFiles: item.files.estimateFiles,
    })
    const [reportFiles, reportFileContainer, reportFileResultContainer] = useFileUploader({
        maxFileCount: 2,

        defaultFiles: item.files.reportFiles,
    })
    const [completeFiles, completeFileContainer, completeFileResultContainer] = useFileUploader({
        maxFileCount: 10,

        defaultFiles: item.files.completeFiles,
    })
    const [documentFiles, documentFileContainer, documentFileResultContainer] = useFileUploader({
        maxFileCount: 0,

        defaultFiles: item.files.documentFiles,
    })
    const [setMessage, messageContainer] = useMessage()
    const onLineHandler = () => {
        modalContext.activeModalHandler(
            <OnlinePayment
                item={item}
                cancelHandler={() => {
                    modalContext.activeModalHandler(null)
                    setItemHandler()
                }}
            />,
            '온라인결제'
        )
    }
    const offLineHandler = () => {
        modalContext.activeModalHandler(
            <OfflinePayment
                item={item}
                cancelHandler={() => {
                    modalContext.activeModalHandler(null)
                    setItemHandler()
                }}
            />,
            '오프라인결제'
        )
    }
    const confirmCostHandler = () => {
        doFetchJson(
            '/service/step/4',
            'PUT',
            JSON.stringify({
                serviceId: item.id,
            }),
            () => {
                alert('견적 승인 처리 되었습니다.')
                setItemHandler()
            },
            (message) => {
                setMessage(message)
            }
        )
    }

    return (
        <Layout active='service2'>
            <div className='container'>
                <form>
                    <div className='wrapper'>
                        <p className='guide'>서비스 정보</p>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>대분류</label>
                        <span>{categories.find((category) => category.id == item.category_depth_1).category_name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>중분류</label>
                        <span>{categories.find((category) => category.id == item.category_depth_2).category_name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>소분류</label>
                        <span>{categories.find((category) => category.id == item.category_depth_3).category_name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>접수제목</label>
                        <span>{item.title}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>요청사항</label>
                        <span dangerouslySetInnerHTML={{ __html: item.content?.replace(/\n/g, '<br/>') }}></span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>진행상황</label>
                        <span>{statusList.find((status) => status.status.toString() == item.status)?.name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>결제수단</label>
                        <span>{payList.find((pay) => item.pay_type == pay.pay)?.name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>첨부사진</label>
                        {upFilesResultContainer}
                    </div>

                    <div className='wrapper'>
                        <p className='guide'>가맹점 정보</p>
                        <div className='wrapper'>
                            <label className='label'>가맹점</label>
                            <span>
                                {item.status > 0 && item.manager ? item.manager.company_name : '선정된 가맹점 없음'}
                            </span>
                        </div>
                    </div>

                    {item.status >= 2 && (
                        <>
                            <div className='wrapper'>
                                <p className='guide'>견적 정보</p>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>서비스일자</label>
                                <span>
                                    {DateFormat.Date(new Date(item.service_date))}{' '}
                                    {atList.find((at) => at.at == item.service_date_at)?.name}
                                </span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>출장비</label>
                                <span>
                                    {globalContext.globalValue.config['apply_travel_cost'] ? (
                                        <span>{RegExp.replaceCost(item.travel_cost.toString())}원</span>
                                    ) : (
                                        <span>
                                            <span className='strike'>
                                                {RegExp.replaceCost(item.travel_cost.toString())}원
                                            </span>
                                            (출장비무료)
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>서비스비용</label>
                                <span>{RegExp.replaceCost(item.cost.toString(), 0)}원</span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>견적서</label>
                                {estimateFileResultContainer}
                            </div>
                        </>
                    )}
                    {item.status >= 5 && (
                        <>
                            <div className='wrapper'>
                                <p className='guide'>완료 정보</p>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>완료일자</label>
                                <span>
                                    {item.complete_at
                                        ? DateFormat.DateTime(new Date(item.complete_at))
                                        : '서비스 진행중'}
                                </span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>완료보고서</label>
                                {item.status == 1 ? reportFileContainer : reportFileResultContainer}
                            </div>
                            <div className='wrapper'>
                                <label className='label'>첨부사진(결과)</label>
                                {item.status == 1 ? completeFileContainer : completeFileResultContainer}
                            </div>
                        </>
                    )}
                    {item.pay && (
                        <>
                            <div className='wrapper'>
                                <p className='guide'>결제 정보</p>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>요청일시</label>
                                <span>{DateFormat.DateTime(new Date(item.pay.create_at))}</span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>결제방식</label>
                                <span>{item.pay.pay_type}</span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>결제금액</label>
                                <span>{item.pay.amount}원</span>
                            </div>
                            {item.pay.pay_type == 'OFFLINE' && (
                                <>
                                    <div className='wrapper'>
                                        <p className='guide'>대금 청구시 필요사항</p>
                                    </div>
                                    <div className='wrapper'>
                                        <label className='label'>내용</label>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: item.description?.replace(/\n/g, '<br/>'),
                                            }}
                                        ></span>
                                    </div>
                                    <div className='wrapper'>
                                        <label className='label'>양식</label>
                                        {documentFileResultContainer}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {messageContainer}

                    <div className='buttons center'>
                        {item.status == 3 ? (
                            <button type='button' className='info' onClick={confirmCostHandler}>
                                견적승인
                            </button>
                        ) : (
                            <button type='button' className='disabled'>
                                견적승인
                            </button>
                        )}
                        {item.status == 6 ? (
                            <button type='button' className='info' onClick={onLineHandler}>
                                온라인결제
                            </button>
                        ) : (
                            <button type='button' className='disabled'>
                                온라인결제
                            </button>
                        )}
                        {item.status == 6 ? (
                            <button type='button' className='info' onClick={offLineHandler}>
                                오프라인결제
                            </button>
                        ) : (
                            <button type='button' className='disabled'>
                                오프라인결제
                            </button>
                        )}
                        {item.finish ? (
                            <button type='button' className='info' onClick={reviewHandler}>
                                만족도조사
                            </button>
                        ) : (
                            <button type='button' className='disabled'>
                                만족도조사
                            </button>
                        )}

                        <button
                            type='button'
                            className='primary'
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            목록
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default ServiceUser

import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserContext } from '../contexts'

const InitPath = () => {
    const navigate = useNavigate()
    const userContext = useContext(UserContext)
    const user = userContext.user
    const [next, setNext] = useState(new URLSearchParams(window.location.search).get('path') ?? '/')
    useEffect(() => {
        if (user.auth && next != '/') {
            navigate(next)
            setNext('/')
        }
    }, [user])

    return null
}

export default InitPath

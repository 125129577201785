import React, { FC, useEffect, useState } from 'react'
import { SignUpFormType } from '../../types/auth'
import { Layout } from '../../layout'

import { useNavigate } from 'react-router-dom'
import { AgreeItems } from '../../components'
import { useMessage } from '../../hooks'
import authList from '../../json/auth.json'
import useFetch from '../../hooks/useFetch'
type Props = {
    setStep: React.Dispatch<number>
    formData: SignUpFormType
}

const ConfirmSignUp: FC<Props> = ({ setStep, formData }) => {
    const navigate = useNavigate()
    const [setMessage, messageContainer] = useMessage()
    const { doFetchJson } = useFetch()
    useEffect(() => {
        if (formData.auth == 1 || formData.auth == 2) {
            setMessage('')
        } else {
            setMessage(
                '서비스 매니저, 관리자의 가입은 최종 가입 대표이사 승인 후 계정이 활성화 됩니다. 활성화 된 경우 이메일로 통보를 합니다.'
            )
        }
    }, [formData.auth])

    const clickBackHandle = () => {
        setStep(0)
    }
    const clickConfirmHandle = (e: React.FormEvent) => {
        e.preventDefault()
        doFetchJson(
            '/auth/signUp',
            'POST',
            JSON.stringify(formData),
            (data) => {
                if (formData.auth == 1 || formData.auth == 2) {
                    alert('회원가입 되었습니다. 로그인 후 이용 해주세요.')
                } else {
                    alert('회원가입 되었습니다. 관리자의 승인 후 로그인이 가능합니다.')
                }
                navigate('/')
            },
            (message) => {
                setMessage(message)
            }
        )
    }

    return (
        <Layout title={`회원 가입`}>
            <form onSubmit={clickConfirmHandle}>
                <div className='container'>
                    <div className='wrapper'>
                        <label className='label'>구분</label>
                        <span>{authList.find((auth) => auth.auth == formData.auth)?.name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>이메일</label>
                        <span>{formData.email}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>핸드폰번호</label>
                        <span>{formData.phone}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>주소</label>
                        <span>
                            [{formData.zipcode}] {formData.address} {formData.addressDetail}
                        </span>
                    </div>
                    {(formData.auth == 1 || formData.auth == 2) && (
                        <div className='wrapper'>
                            <label className='label'>기관명(업체명)</label>
                            <span>{formData.companyName}</span>
                        </div>
                    )}

                    {formData.auth == 3 && (
                        <>
                            <div className='wrapper'>
                                <label className='label'>대표자</label>
                                <span>{formData.ceoName}</span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>사업자 등록번호</label>
                                <span>{formData.businessNumber}</span>
                            </div>
                            <div className='wrapper'>
                                <label className='label'>업종별 면허번호</label>
                                <span>{formData.licenseNumber}</span>
                            </div>
                        </>
                    )}
                    {formData.auth == 4 && (
                        <></>
                        // <div className='wrapper'>
                        //     <label className='label'>대표이사 이메일</label>
                        //     <span>{formData.ceoEmail}</span>
                        // </div>
                    )}
                    <AgreeItems />
                    {messageContainer}

                    <div className='buttons center'>
                        <button type='button' onClick={clickBackHandle} className='primary'>
                            수정하기
                        </button>
                        <button type='submit' className='info'>
                            {formData.auth == 1 || formData.auth == 2 ? '확인' : '승인 요청'}
                        </button>
                    </div>
                </div>
            </form>
        </Layout>
    )
}

export default ConfirmSignUp

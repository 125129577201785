import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { ServiceRegistDataType } from '../../types/service'
import { Layout } from '../../layout'
import { GlobalContext, ModalContext } from '../../contexts'
import { ServiceCategoryType } from '../../types'
import pdfIcon from '../../icons/pdf_icon.svg'
import txtIcon from '../../icons/txt_icon.svg'
import xlsIcon from '../../icons/xls_icon.svg'
import zipIcon from '../../icons/zip_icon.svg'
import pptIcon from '../../icons/ppt_icon.svg'
import anyIcon from '../../icons/any_icon.svg'
import Preview from '../../components/Preview'
import atList from '../../json/at.json'
import payList from '../../json/pay.json'
import useFileUploader from '../../hooks/useFileUploader'
type Props = {
    formData: ServiceRegistDataType
    submitHandler: (e: React.FormEvent) => void
    messageContainer: ReactNode
    cancleHandler: () => void
    files: File[]
    fileUploadContainer: ReactNode
    includeHoliDay: boolean
}
const ServiceRegistResult: FC<Props> = ({
    formData,
    submitHandler,
    cancleHandler,
    files,
    fileUploadContainer,
    includeHoliDay,
}) => {
    const globalContext = useContext(GlobalContext)

    const category: ServiceCategoryType[] = globalContext.globalValue.serviceCategory
    return (
        <Layout active='service1'>
            <div className='container'>
                <form className='form' onSubmit={submitHandler}>
                    <div className='wrapper'>
                        <label className='label'>담당자 이메일</label>
                        <span>{formData.email}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>대분류</label>
                        <span>
                            {
                                category.find((cate) => {
                                    return cate.id == formData.categoryDepth1
                                })?.category_name
                            }
                        </span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>중분류</label>
                        <span>
                            {
                                category.find((cate) => {
                                    return cate.id == formData.categoryDepth2
                                })?.category_name
                            }
                        </span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>소분류</label>
                        <span>
                            {
                                category.find((cate) => {
                                    return cate.id == formData.categoryDepth3
                                })?.category_name
                            }
                        </span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>기업명</label>
                        <span>{formData.companyName}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>부서명</label>
                        <span>{formData.teamName}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>현장주소</label>
                        <span>
                            [{formData.zipcode}] {formData.address} {formData.addressDetail}
                        </span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>담당자명</label>
                        <span>{formData.name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>담당자 폰 번호</label>
                        <span>{formData.phone}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>접수제목</label>
                        <span>{formData.title}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>요청사항</label>
                        <span dangerouslySetInnerHTML={{ __html: formData.content?.replace(/\n/g, '<br/>') }}></span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>첨부자료</label>
                        {fileUploadContainer}
                        {/* <div className='items'>{filesNode}</div> */}
                    </div>
                    <div className='wrapper'>
                        <label className='label'>요청일자</label>
                        <div className='items'>
                            <p className='item'>{`${formData.serviceDate1} ${
                                atList.find((at) => at.at == formData.serviceDate1At)?.name
                            }`}</p>
                            <p className='item'>{`${formData.serviceDate2} ${
                                atList.find((at) => at.at == formData.serviceDate2At)?.name
                            }`}</p>
                            <p className='item'>{`${formData.serviceDate3} ${
                                atList.find((at) => at.at == formData.serviceDate3At)?.name
                            }`}</p>
                        </div>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>결제수단</label>
                        <span>{payList.find((pay) => formData.payType == pay.pay)?.name}</span>
                    </div>
                    <div className='wrapper'>
                        <label className='label'>출장비</label>
                        <span>
                            <span
                                style={{ width: 'auto' }}
                                className={`${
                                    formData.travelCost !== 0 && !globalContext.globalValue.config['apply_travel_cost']
                                        ? 'strike'
                                        : ''
                                }`}
                            >
                                {formData.travelCost} 원
                            </span>
                            {!globalContext.globalValue.config['apply_travel_cost'] && formData.travelCost !== 0 ? (
                                <span
                                    style={{
                                        color: 'red',
                                        fontSize: 13,
                                        marginLeft: 8,
                                    }}
                                >
                                    출장비 전액할인
                                </span>
                            ) : null}
                            {includeHoliDay && (
                                <b
                                    style={{
                                        color: 'red',
                                        fontSize: 13,
                                        marginLeft: 8,
                                    }}
                                >
                                    주말 및 공휴일 포함
                                </b>
                            )}
                        </span>
                        {/* <span>{formData.travelCost}원</span> */}
                    </div>

                    <div className='buttons center'>
                        <button type='submit' className='primary'>
                            신청하기
                        </button>
                        <button type='button' className='error' onClick={cancleHandler}>
                            수정하기
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default ServiceRegistResult

import React, { FC } from 'react'
import { SignUpFormType } from '../../types/auth'
type Props = {
    formData: SignUpFormType
    changeHandler: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}
const AdminSignUpForm: FC<Props> = ({ formData, changeHandler }) => {
    return (
        <></>
        // <div className='wrapper'>
        //     <label className='label'>대표이사 이메일</label>
        //     <input type='text' name='ceoEmail' defaultValue={formData.ceoEmail} onChange={changeHandler} />
        // </div>
    )
}

export default AdminSignUpForm
